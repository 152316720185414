// 0.0.0
@charset "UTF-8";

// ----------  ----------
// フォントサイズ汎用クラス
.fs12 {
    font-size: 1.2rem;
    @include max-screen($tablet) {
        font-size: 1.1rem;
    }
    @include max-screen($mobile) {
        font-size: 1.0rem;
    }
}

.fs13 {
    font-size: 1.3rem;
    @include max-screen($tablet) {
        font-size: 1.2rem;
    }
    @include max-screen($mobile) {
        font-size: 1.1rem;
    }
}

.fs14 {
    font-size: 1.4rem;
    @include max-screen($tablet) {
        font-size: 1.3rem;
    }
    @include max-screen($mobile) {
        font-size: 1.2rem;
    }
}

.fs15 {
    font-size: 1.5rem;
    @include max-screen($tablet) {
        font-size: 1.4rem;
    }
    @include max-screen($mobile) {
        font-size: 1.3rem;
    }
}

.fs16 {
    font-size: 1.6rem;
    @include max-screen($tablet) {
        font-size: 1.5rem;
    }
    @include max-screen($mobile) {
        font-size: 1.4rem;
    }
}

.fs17 {
    font-size: 1.7rem;
    @include max-screen($tablet) {
        font-size: 1.6rem;
    }
    @include max-screen($mobile) {
        font-size: 1.5rem;
    }
}

.fs18 {
    font-size: 1.8rem;
    @include max-screen($tablet) {
        font-size: 1.7rem;
    }
    @include max-screen($mobile) {
        font-size: 1.6rem;
    }
}

.fs19 {
    font-size: 1.9rem;
    @include max-screen($tablet) {
        font-size: 1.8rem;
    }
    @include max-screen($mobile) {
        font-size: 1.7rem;
    }
}

.fs20 {
    font-size: 2.0rem;
    @include max-screen($tablet) {
        font-size: 1.9rem;
    }
    @include max-screen($mobile) {
        font-size: 1.8rem;
    }
}

.fs21 {
    font-size: 2.1rem;
    @include max-screen($tablet) {
        font-size: 2.0rem;
    }
    @include max-screen($mobile) {
        font-size: 1.9rem;
    }
}

.fs22 {
    font-size: 2.2rem;
    @include max-screen($tablet) {
        font-size: 2.1rem;
    }
    @include max-screen($mobile) {
        font-size: 2.0rem;
    }
}

.fs23 {
    font-size: 2.3rem;
    @include max-screen($tablet) {
        font-size: 2.2rem;
    }
    @include max-screen($mobile) {
        font-size: 2.1rem;
    }
}

.fs24 {
    font-size: 2.4rem;
    @include max-screen($tablet) {
        font-size: 2.3rem;
    }
    @include max-screen($mobile) {
        font-size: 2.2rem;
    }
}

.fs25 {
    font-size: 2.5rem;
    @include max-screen($tablet) {
        font-size: 2.4rem;
    }
    @include max-screen($mobile) {
        font-size: 2.3rem;
    }
}

.fs26 {
    font-size: 2.6rem;
    @include max-screen($tablet) {
        font-size: 2.5rem;
    }
    @include max-screen($mobile) {
        font-size: 2.4rem;
    }
}

.fs27 {
    font-size: 2.7rem;
    @include max-screen($tablet) {
        font-size: 2.6rem;
    }
    @include max-screen($mobile) {
        font-size: 2.5rem;
    }
}

.fs28 {
    font-size: 2.8rem;
    @include max-screen($tablet) {
        font-size: 2.7rem;
    }
    @include max-screen($mobile) {
        font-size: 2.6rem;
    }
}

.fs29 {
    font-size: 2.9rem;
    @include max-screen($tablet) {
        font-size: 2.8rem;
    }
    @include max-screen($mobile) {
        font-size: 2.7rem;
    }
}

.fs30 {
    font-size: 3.0rem;
    @include max-screen($tablet) {
        font-size: 2.9rem;
    }
    @include max-screen($mobile) {
        font-size: 2.8rem;
    }
}

.font-s12 {
    font-size: 1.2rem !important;
    @include max-screen($tablet) {
        font-size: 1.1rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.0rem !important;
    }
}

.font-s13 {
    font-size: 1.3rem !important;
    @include max-screen($tablet) {
        font-size: 1.2rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.1rem !important;
    }
}

.font-s14 {
    font-size: 1.4rem !important;
    @include max-screen($tablet) {
        font-size: 1.3rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.2rem !important;
    }
}

.font-s15 {
    font-size: 1.5rem !important;
    @include max-screen($tablet) {
        font-size: 1.4rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.3rem !important;
    }
}

.font-s16 {
    font-size: 1.6rem !important;
    @include max-screen($tablet) {
        font-size: 1.5rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.4rem !important;
    }
}

.font-s17 {
    font-size: 1.7rem !important;
    @include max-screen($tablet) {
        font-size: 1.6rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.5rem !important;
    }
}

.font-s18 {
    font-size: 1.8rem !important;
    @include max-screen($tablet) {
        font-size: 1.7rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.6rem !important;
    }
}

.font-s19 {
    font-size: 1.9rem !important;
    @include max-screen($tablet) {
        font-size: 1.8rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.7rem !important;
    }
}

.font-s20 {
    font-size: 2.0rem !important;
    @include max-screen($tablet) {
        font-size: 1.9rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.8rem !important;
    }
}

.font-s21 {
    font-size: 2.1rem !important;
    @include max-screen($tablet) {
        font-size: 2.0rem !important;
    }
    @include max-screen($mobile) {
        font-size: 1.9rem !important;
    }
}

.font-s22 {
    font-size: 2.2rem !important;
    @include max-screen($tablet) {
        font-size: 2.1rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.0rem !important;
    }
}

.font-s23 {
    font-size: 2.3rem !important;
    @include max-screen($tablet) {
        font-size: 2.2rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.1rem !important;
    }
}

.font-s24 {
    font-size: 2.4rem !important;
    @include max-screen($tablet) {
        font-size: 2.3rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.2rem !important;
    }
}

.font-s25 {
    font-size: 2.5rem !important;
    @include max-screen($tablet) {
        font-size: 2.4rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.3rem !important;
    }
}

.font-s26 {
    font-size: 2.6rem !important;
    @include max-screen($tablet) {
        font-size: 2.5rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.4rem !important;
    }
}

.font-s27 {
    font-size: 2.7rem !important;
    @include max-screen($tablet) {
        font-size: 2.6rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.5rem !important;
    }
}

.font-s28 {
    font-size: 2.8rem !important;
    @include max-screen($tablet) {
        font-size: 2.7rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.6rem !important;
    }
}

.font-s29 {
    font-size: 2.9rem !important;
    @include max-screen($tablet) {
        font-size: 2.8rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.7rem !important;
    }
}

.font-s30 {
    font-size: 3.0rem !important;
    @include max-screen($tablet) {
        font-size: 2.9rem !important;
    }
    @include max-screen($mobile) {
        font-size: 2.8rem !important;
    }
}


// ----------  ----------
// テキスト揃え汎用クラス
.t-center {
    text-align: center;
}

.t-left {
    text-align: left;
}

.t-right {
    text-align: right;
}

.txt-center {
    text-align: center !important;
}

.txt-left {
    text-align: left !important;
}

.txt-right {
    text-align: right !important;
}


// ----------  ----------
// ブロック要素汎用クラス
.block {
    display: block;
}

.iblock {
    display: inline-block;
}

.inline {
    display: inline;
}

.pc-block {
    display: block;
    @include max-screen($tablet) {
        display: none;
    }
}

.pc-iblock {
    display: inline-block;
    @include max-screen($tablet) {
        display: none;
    }
}

.sp-block {
    display: none;
    @include max-screen($tablet) {
        display: block;
    }
}

.sp-iblock {
    display: none;
    @include max-screen($tablet) {
        display: inline-block;
    }
}

.sp-visible {
    @media screen and (min-width: 769px) {
        display: none !important;
    }
}

.pc-visible {
    @include max-screen($tablet) {
        display: none !important;
    }
}

.mb-visible {
    @media screen and (min-width: 415px) {
        display: none !important;
    }
}

.tb-visible {
    @include max-screen($mobile) {
        display: none !important;
    }
}

// ----------  ----------
// マージン汎用クラス
$marginSize: (0, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100);
@each $px in $marginSize {
    .mt#{$px} {
        margin-top: #{$px}px !important;
        @media screen and (max-width: 768px) {
            margin-top: #{$px/2}px !important;
        }
    }
    .mr#{$px} {
        margin-right: #{$px}px !important;
        @media screen and (max-width: 768px) {
            margin-right: #{$px/2}px !important;
        }
    }
    .mb#{$px} {
        margin-bottom: #{$px}px !important;
        @media screen and (max-width: 768px) {
            margin-bottom: #{$px/2}px !important;
        }
    }
    .ml#{$px} {
        margin-left: #{$px}px !important;
        @media screen and (max-width: 768px) {
            margin-left: #{$px/2}px !important;
        }
    }
}


// ----------  ----------
// 【その他汎用クラス】
.telephone {
    a {
        cursor: default;
        &:hover {
            opacity: 1;
        }
        .android &,
        .iphone & {
            opacity: .7;
        }
    }
}

.wrapper {
    width: $base_w;
    margin-left: auto;
    margin-right: auto;
    @include max-screen($tablet) {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

#pageTop {
    z-index: 400;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    @include max-screen($tablet) {
        bottom: 0;
        right: 0;
        width: 45px;
        height: 45px;
    }
    a {
        display: block;
        width: 100%;
        height: 100%;
        background-color: #000000;
        color: #ffffff;
        &:before {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-59%);
            width: 100%;
            content: "\f077";
            font-family: FontAwesome;
            font-size: 2.4rem;
            text-align: center;
            line-height: 1;
            @include max-screen($tablet) {
                font-size: 2.0rem;
            }
        }
        &:hover {
            opacity: 0.5;
        }
    }
}

.cf {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}
.nowrap {
    white-space: nowrap;
}
.fw500{
    font-weight: 500;
}
.fw-bold {
    font-weight: bold;
}
.fs-underline {
    text-decoration: underline;
}
.fs-strike {
    text-decoration: line-through;
}
.fc-base {
    color: $base_c !important;
}
.fc-link {
    color: $link_c !important;
}
.fc-yellow {
    color: #fff669 !important;
}
.fc-white {
    color: #fff !important;
}
.fc-red {
    color: #d92139 !important;
}
.fc-blue {
    color: #0071ad !important;
}

.max800 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.max900 {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}
.max940 {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
}
.max950 {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 768px) {
        max-width: 640px;
    }
}
.max1000 {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

$width-list: 530,750,820,880,900,940,960,975,980,1000,1020,1060; //横幅


@each $width in $width-list {
    .w#{$width} {
        width: #{$width}px;
        max-width: 100%;
        margin: auto;
    }
}
