@charset "UTF-8";

@include max-screen($tablet) {}

@include max-screen($mobile) {}

.hiragino {
    font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
    font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
}

.fc-lp-orange {
    color: #fc6238;
}

.fc-lp-green {
    color: #00966e;
}

//section
.sec-lp {
    .sec-lp-bnr {
        padding: 50px 0;

        @media screen and (max-width: 768px) {
            padding: 30px 0;
        }

        a {
            max-width: 100%;

            img {
                max-width: 100%;
            }
        }
    }

    .sec-lp-welcome {
        padding: 40px 0px 80px;
        background: url(../img/lp/bg_01.png);

        @media screen and (max-width: 768px) {
            padding: 20px 0px 40px;
        }

        .unit-lp-title-01 {
            &::before {
                content: "";
                display: block;
                width: 309px;
                height: 95px;
                margin: 0 auto;
                background: url(../img/lp/title_deco_01.png)center center /contain no-repeat;
                transform: translateX(-20px);

                @media screen and (max-width: 414px) {
                    width: 155px;
                    height: 48px;
                }
            }
        }

        .sub-title {
            font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
            font-weight: 300;
            font-size: 3rem;
            text-align: center;
            letter-spacing: 0.08em;
            font-feature-settings: "palt";

            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 1.6rem;
            }
        }

        .bg-deco {
            background: url(../img/lp/bg_02.png)center center /contain repeat-x;
            padding: 60px 0px 70px;

            @media screen and (max-width: 768px) {
                padding: 30px 0;
                background: url(../img/lp/bg_02.png)center center /cover repeat-x;
            }
        }
    }

    .sec-lp-rec {
        padding: 70px 0px;
        background: rgb(32, 226, 215);
        background: linear-gradient(90deg, rgba(32, 226, 215, 1) 0%, rgba(249, 254, 165, 1) 100%);
        text-align: center;

        @media screen and (max-width: 768px) {
            padding: 35px 0;
            background: rgb(32, 226, 215);
            background: linear-gradient(0deg, rgba(32, 226, 215, 1) 0%, rgba(249, 254, 165, 1) 100%);


            .unit-lp-marker-01 {
                background: linear-gradient(transparent 50%, #fff 50%);
                display: inline-block;
                line-height: 1.2;
            }
        }

        .sub-title {
            text-align: center;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-weight: 600;
            font-size: 2.6rem;
            letter-spacing: 0.08em;
            font-feature-settings: "palt";
            margin-bottom: 20px;

            @media screen and (max-width: 768px) {
                font-size: 2.2rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 1.5rem;
                letter-spacing: 0.04em;
            }
        }

        .unit-lp-title-01 {
            text-shadow: -1px -1px 0px #fff;

            @media screen and (max-width: 414px) {
                letter-spacing: 0;
            }

            @media screen and (min-width: 769px) {
                &::before {
                    content: "";
                    display: inline-block;
                    width: 63px;
                    height: 63px;
                    vertical-align: middle;
                    padding-right: 30px;
                    background: url(../img/lp/title_deco_02.png)center center /contain no-repeat;
                }

                &::after {
                    content: "";
                    display: inline-block;
                    width: 63px;
                    height: 63px;
                    vertical-align: middle;
                    padding-left: 30px;
                    background: url(../img/lp/title_deco_03.png)center center /contain no-repeat;
                }
            }
        }
    }

    .sec-lp-now {
        padding: 80px 0px 90px;

        @media screen and (max-width: 768px) {
            padding: 40px 0;
        }

        .unit-lp-title-01 {
            span {
                position: relative;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 121px;
                    height: 82px;
                    padding-right: 50px;
                    background: url(../img/lp/title_deco_12.png)center center /contain no-repeat;

                    @media screen and (max-width: 768px) {
                        position: absolute;
                        left: -80px;
                        top: -20px;
                        padding-right: 0;
                        width: 80px;
                        height: 53px;
                    }

                    @media screen and (max-width: 414px) {
                        width: 60px;
                        height: 40px;
                    }
                }

                &::after {
                    content: "";
                    display: inline-block;
                    width: 121px;
                    height: 82px;
                    padding-left: 50px;
                    background: url(../img/lp/title_deco_13.png)center center /contain no-repeat;

                    @media screen and (max-width: 768px) {
                        position: absolute;
                        right: -80px;
                        top: -20px;
                        padding-left: 0;
                        width: 80px;
                        height: 53px;
                    }

                    @media screen and (max-width: 414px) {
                        width: 60px;
                        height: 40px;
                    }
                }
            }
        }

        .sub-title {
            text-align: center;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-weight: 600;
        }
    }

    .sec-lp-cta {
        padding: 60px 0;
        text-align: center;

        @media screen and (max-width: 768px) {
            padding: 30px 0;
        }

        .main-title {
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-weight: 600;
            letter-spacing: 0.1em;
            font-size: 3rem;

            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 1.6rem;
            }

            .deco {
                font-weight: 600;
                font-size: 3rem;
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                display: inline-block;

                .dot {
                    position: relative;

                    &::before {
                        content: "・";
                        position: absolute;
                        top: -1em;
                        left: 50%;
                        transform: translateX(-50%);

                        @media screen and (max-width: 768px) {
                            top: -0.85em;
                        }

                        @media screen and (max-width: 414px) {
                            top: -0.95em;
                        }
                    }
                }

                @media screen and (max-width: 768px) {
                    font-size: 2.4rem;
                }

                @media screen and (max-width: 414px) {
                    font-size: 2rem;
                }
            }
        }

        .unit-lp-btn-01 {
            padding-top: 30px;
            padding-bottom: 20px;
        }

        .tel-text {
            .text {
                font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
                font-weight: 300;
                color: #fc6250;
                font-size: 2.2rem;
                letter-spacing: 0.07em;

                @media screen and (max-width: 768px) {
                    font-size: 1.8rem;
                }
            }

            .tel {
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                font-weight: 600;
                font-size: 3rem;
                color: #fc6250;

                &::before {
                    content: "\f095";
                    @include fa5;
                    margin-right: 0.5em;
                    font-size: 2rem;
                }

                @media screen and (max-width: 768px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 414px) {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .sec-lp-device {
        height: 500px;
        background: url(../img/lp/bg_03.jpg)center right /cover no-repeat;
        padding: 80px 0px 60px;

        @media screen and (max-width: 768px) {
            padding: 0px 0px 30px;
            background: #fafeef;
            height: auto;

            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 300px;
                background: url(../img/lp/bg_03_sp.jpg)center right /cover no-repeat;
                margin-bottom: 40px;
            }
        }

        .sub-title {
            font-size: 3rem;
            font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
            font-feature-settings: "palt";
            font-weight: 300;
            letter-spacing: 0.08em;

            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 1.6rem;
            }
        }

        .main-title {
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-weight: 600;
            letter-spacing: 0.1em;
            font-size: 4rem;
            font-feature-settings: "palt";
            margin-top: 20px;
            line-height: 1.4;

            @media screen and (max-width: 768px) {
                font-size: 2.8rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 2.2rem;
            }
        }

        .unit-lp-text-01 {
            max-width: 520px;
            margin-top: 40px;

            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
        }
    }

    .sec-lp-sample {
        background: url(../img/lp/bg_04.jpg);
        padding: 60px 0px 100px;
        text-align: center;

        @media screen and (max-width: 768px) {
            padding: 60px 0px 50px;
        }

        .box-col3-01 {
            margin-top: 30px;

            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 3px;
                background: url(../img/lp/bg_05.svg);
                background-repeat: repeat-x;
                margin-bottom: 30px;
            }
        }

        .box-col4-01 {
            &.sp-visible {
                margin-top: 20px;

                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 3px;
                    background: url(../img/lp/bg_05.svg);
                    background-repeat: repeat-x;
                    margin-bottom: 20px;
                }
            }
        }

        .sub-text {
            font-size: 2.6rem;
            font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
            font-weight: 300;
            letter-spacing: 0.08em;
            font-feature-settings: "palt";

            @media screen and (max-width: 768px) {
                font-size: 2.2rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 1.8rem;
            }
        }

        .main-text {
            font-size: 3.4rem;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-weight: 600;
            letter-spacing: 0.08em;
            font-feature-settings: "palt";
            margin-top: 10px;

            @media screen and (max-width: 768px) {
                font-size: 2.8rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 2rem;
            }
        }
    }

    .sec-lp-difference {
        padding: 50px 0px 90px;

        @media screen and (max-width: 768px) {
            padding: 25px 0px 45px;
        }

        .unit-lp-title-01 {
            &::before {
                content: "";
                display: block;
                width: 441px;
                height: 103px;
                margin: 0 auto;
                background: url(../img/lp/title_deco_05.png)center center /contain no-repeat;

                @media screen and (max-width: 768px) {
                    max-width: 220px;
                    height: 50px;
                }
            }

            span {
                position: relative;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 121px;
                    height: 81px;
                    padding-right: 50px;
                    background: url(../img/lp/title_deco_06.png)center center /contain no-repeat;

                    @media screen and (max-width: 768px) {
                        position: absolute;
                        left: -80px;
                        top: -20px;
                        width: 80px;
                        padding-right: 0px;
                        height: 53px;
                    }

                    @media screen and (max-width: 414px) {
                        width: 60px;
                        height: 40px;
                        left: -100px;
                    }

                    @media screen and (max-width: 320px) {
                        left: -70px;
                    }
                }

                &::after {
                    content: "";
                    display: inline-block;
                    width: 121px;
                    height: 81px;
                    padding-left: 50px;
                    background: url(../img/lp/title_deco_07.png)center center /contain no-repeat;

                    @media screen and (max-width: 768px) {
                        position: absolute;
                        right: -80px;
                        top: -20px;
                        width: 80px;
                        padding-left: 0px;
                        height: 53px;
                    }

                    @media screen and (max-width: 414px) {
                        width: 60px;
                        height: 40px;
                        right: -100px;
                    }

                    @media screen and (max-width: 320px) {
                        right: -70px;
                    }
                }
            }
        }
    }

    .sec-lp-monthly {
        padding: 80px 0px 70px;
        background: url(../img/lp/bg_07.png), linear-gradient(90deg, rgba(250, 217, 97, 1) 0%, rgba(247, 107, 28, 1) 100%);
        background-position: 100%;

        @media screen and (max-width: 768px) {
            padding: 80px 0px 35px;
        }
    }

    .sec-lp-price {
        background: url(../img/lp/bg_01.png);

        .wrapper {
            position: relative;
            padding: 70px 0px 80px;

            @media screen and (max-width: 768px) {
                padding: 35px 15px 40px;
            }

            &::before {
                content: "";
                display: block;
                width: 322px;
                height: 56px;
                margin: 0 auto;
                background: url(../img/lp/sec_deco_01.png)center center /contain no-repeat;
                position: absolute;
                left: -10px;
                top: 0;

                @media screen and (max-width: 768px) {
                    width: 200px;
                    height: 35px;
                    left: 0;
                }

                @media screen and (max-width: 414px) {
                    width: 161px;
                    height: 28px;
                }
            }

            &::after {
                content: "";
                display: block;
                width: 322px;
                height: 56px;
                margin: 0 auto;
                background: url(../img/lp/sec_deco_01.png)center center /contain no-repeat;
                position: absolute;
                right: -10px;
                top: 0;

                @media screen and (max-width: 768px) {
                    width: 200px;
                    height: 35px;
                    right: 0;
                }

                @media screen and (max-width: 414px) {
                    width: 161px;
                    height: 28px;
                }
            }
        }

        .unit-lp-title-01 {
            &::before {
                content: "";
                display: block;
                width: 269px;
                height: 80px;
                margin: 0 auto;
                background: url(../img/lp/title_deco_09.png)center center /contain no-repeat;

                @media screen and (max-width: 414px) {
                    width: 135px;
                    height: 40px;
                }
            }
        }

        .sub-text {
            color: #f15a24;
            text-align: center;
            font-weight: 600;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-size: 2.0rem;
            border: 3px solid #f15a24;
            border-radius: 10px;
            display: inline-block;
            padding: 10px 30px;
            line-height: 1.5;

            @media screen and (max-width: 768px) {
                font-size: 1.8rem;
            }
        }
    }

    .sec-lp-voice {
        padding: 80px 0px 110px;
        background: rgb(32, 226, 215);
        background: linear-gradient(90deg, rgba(32, 226, 215, 1) 0%, rgba(249, 254, 165, 1) 100%);

        @media screen and (max-width: 768px) {
            padding: 40px 0px 55px;
            background: rgb(32, 226, 215);
            background: linear-gradient(0deg, rgba(32, 226, 215, 1) 0%, rgba(249, 254, 165, 1) 100%);
        }

        .unit-lp-title-01 {
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                vertical-align: top;
                width: 120px;
                height: 120px;
                margin: 0 auto;
                background: url(../img/lp/title_deco_10.png)center center /contain no-repeat;

                @media screen and (max-width: 414px) {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    .sec-lp-teacher {
        padding: 70px 0;
        background: #f2fcdd;

        @media screen and (max-width: 768px) {
            padding: 35px 0;
        }
    }

    .sec-lp-flow {
        padding: 70px 0;

        @media screen and (max-width: 768px) {
            padding: 35px 0;
        }
    }

    .sec-lp-faq {
        padding: 70px 0;
        background: url(../img/lp/bg_01.png);

        @media screen and (max-width: 768px) {
            padding: 35px 0;
        }
    }

    .sec-lp-shool {
        padding: 70px 0;
        background: #ecf084;

        @media screen and (max-width: 768px) {
            padding: 35px 0;
        }

        .unit-lp-title-01 {
            &::before {
                content: "";
                display: block;
                width: 64px;
                height: 60px;
                margin: 0 auto;
                background: url(../img/lp/title_deco_11.png)center center /contain no-repeat;
            }
        }

        .box-col3-01 {
            flex-wrap: nowrap;

            .inner {
                width: 48%;
                margin: 0 auto;

                figure {
                    img {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

//box
.box-lp-teacher-01 {
    .slider {
        .slick-list {
            margin-bottom: 40px;

            @media screen and (max-width: 768px) {
                margin-bottom: 20px;
            }

            li {
                padding: 30px;
                background: #fff;
                border-radius: 10px;
                margin: 0 20px;
                width: 300px;

                @media screen and (max-width: 768px) {
                    padding: 15px;
                }

                .inner {
                    .thum {
                        img {
                            display: block;
                            transition: .2s ease-out;
                            height: 220px;
                            margin: 0 auto 10px;
                        }
                    }

                    .name-wrap {
                        text-align: center;
                        font-weight: 600;
                        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;

                        .name {
                            font-size: 2.2rem;
                            color: #1a1a1a;

                            @media screen and (max-width: 768px) {
                                font-size: 1.8rem;
                            }
                        }

                        .year {
                            font-size: 1.4rem;
                            color: #00b478;

                            @media screen and (max-width: 768px) {
                                font-size: 1.3rem;
                            }
                        }
                    }

                    .txt {
                        font-size: 1.4rem;
                        font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
                        font-weight: 300;
                        margin-top: 15px;
                    }
                }
            }
        }

        .slick-dots {
            bottom: -45px;

            li {
                button {
                    &::before {
                        color: #fff;
                        opacity: 1;
                    }
                }

                &.slick-active {
                    button {
                        &::before {
                            color: #4cccaa;
                        }
                    }
                }
            }
        }
    }
}

.box-lp-step-01 {
    display: flex;
    flex-wrap: wrap;
    counter-reset: step01;

    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }

    > * {
        position: relative;
        counter-increment: step01;

        .step-inner {
            border-radius: 3px;
            background: #fff;

            .step-badge {
                position: absolute;
                top: -15px;
                left: -20px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 54px;
                width: 54px;
                border: 3px solid #1b96f3;
                border-radius: 50%;
                background: #1b96f3;
                text-align: center;
                color: #fff;
                line-height: 1.25;
                padding-top: 0.25em;

                &::before {
                    content: "";
                    position: absolute;
                    bottom: 2px;
                    right: 2px;
                    display: block;
                    height: 5px;
                    width: 5px;
                    border-bottom: 2px solid #1b96f3;
                    border-right: 2px solid #1b96f3;
                    background: #1b96f3;
                    transform: rotate(-45deg) scaleY(1.5) rotate(45deg);
                }

                .num {
                    font-weight: bold;
                    @include poppins;
                    font-size: 1.1rem;
                    letter-spacing: 0.1em;

                    &::after {
                        content: counter(step01);
                        display: block;
                        font-size: 2rem;
                    }
                }
            }

            .step-figure {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                }
            }

            .step-detail {
                padding: 30px 15px;
                background: #f5f5f5;
                font-weight: 600;
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                letter-spacing: 0.08em;
                text-align: center;

                .detail-title {
                    font-size: 1.8rem;
                    @include smooth;
                    line-height: 1.666;

                    @media screen and (max-width: 768px) {
                        font-size: 1.6rem;
                    }

                    & + * {
                        margin-top: 5px;
                    }
                }

                .sub-text {
                    color: #1b96f3;
                    font-size: 1.4rem;
                    letter-spacing: 0.08em;
                    line-height: 1.43;
                }
            }
        }
    }

    &.col4 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(100%/4 - 120px/4);

                &:not(:nth-child(4n)) {
                    margin-right: 40px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            justify-content: space-between;

            & > * {
                width: 49%;

                &:nth-of-type(n+3) {
                    margin-top: 40px;
                }
            }
        }

        @media screen and (max-width: 414px) {
            justify-content: space-between;

            & > * {
                width: 100%;

                &:nth-of-type(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }

    &.green {
        > * {
            .step-inner {
                .step-badge {
                    border: 3px solid #00b478;
                    background: #00b478;

                    &::before {
                        border-bottom: 2px solid #00b478;
                        border-right: 2px solid #00b478;
                        background: #00b478;
                    }
                }
            }
        }
    }
}

.box-lp-step-02 {
    display: flex;
    flex-wrap: wrap;

    @media screen and (min-width: 769px) {
        & > * {
            width: calc(32% - 20px);

            @media screen and (min-width: 769px) {
                &:not(:nth-child(3n)) {
                    margin-right: 40px;
                }

                &:nth-child(n+4) {
                    margin-top: 40px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        & > * {
            width: 100%;

            &:nth-child(n+2) {
                margin-top: 30px;
            }
        }
    }

    .bg-inner {
        padding: 40px 40px 20px;
        background: #fff;
        box-shadow: 0 0 8px 0 rgba(#000, 0.06);
        border-radius: 10px;

        @media screen and (max-width: 768px) {
            padding: 30px 15px 20px;
        }

        .bg-title {
            display: table;
            padding: 5px 50px;
            background: #333333;
            border-radius: 9999px;
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.15em;
            margin: -60px auto 30px auto;

            @media screen and (max-width: 768px) {
                margin: -40px auto 15px auto;
            }
        }

        .title {
            text-align: center;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.4;

            @media screen and (max-width: 768px) {
                font-size: 1.8rem;
            }
        }

        .unit-text-01 {
            img {
                width: auto;
            }
        }
    }
}

.box-lp-rec-01 {
    display: flex;
    justify-content: space-between;
    background: url(../img/lp/rec_img_01.png)center bottom /auto no-repeat;

    @media screen and (max-width: 768px) {
        background: url(../img/lp/rec_img_01.png)center top /auto no-repeat;
        padding-top: 280px;
    }

    @media screen and (max-width: 414px) {
        background: url(../img/lp/rec_img_01.png)center top /contain no-repeat;
    }

    .box-left {
        @media screen and (max-width: 768px) {
            width: 49%;
        }

        .inner {
            margin-left: 0;

            @media screen and (min-width: 769px) {
                &:nth-of-type(2n) {
                    margin-left: 40px;
                }
            }

            @media screen and (max-width: 768px) {
                &:first-of-type {
                    &::before {
                        content: "";
                        background: url(../img/lp/rec_comment_left_sp.png)center center / contain no-repeat;
                        width: 64px;
                        height: 35px;
                        display: block;
                        position: absolute;
                        top: -24px;
                        left: 45px;

                        @media screen and (max-width: 414px) {
                            left: 15px;
                        }
                    }
                }
            }
        }
    }

    .box-right {
        @media screen and (max-width: 768px) {
            width: 49%;
        }

        .inner {
            margin-left: 0;

            @media screen and (min-width: 769px) {
                &:nth-of-type(2n-1) {
                    margin-left: 40px;
                }
            }

            @media screen and (max-width: 768px) {
                &:first-of-type {
                    &::before {
                        content: "";
                        background: url(../img/lp/rec_comment_right_sp.png)center center / contain no-repeat;
                        width: 64px;
                        height: 35px;
                        display: block;
                        position: absolute;
                        top: -24px;
                        right: 45px;

                        @media screen and (max-width: 414px) {
                            right: 15px;
                        }
                    }
                }
            }
        }
    }
}

.box-lp-sample-01 {
    width: 100%;
    height: auto;
    padding: 3px;
    background: linear-gradient(0deg, rgba(197, 241, 133, 1) 0%, rgba(141, 235, 179, 1) 100%);
    border-radius: 10px;
    margin-bottom: 20px;

    .box-inner {
        padding: 3px;
        background-color: #FFF;
        border-radius: 9px;
        padding: 50px 30px 40px;
        position: relative;

        &::before {
            content: "";
            display: block;
            width: 312px;
            height: 75px;
            margin: 0 auto;
            background: url(../img/lp/title_deco_04.png)center center /contain no-repeat;
            position: absolute;
            top: -35px;
            left: 50%;
            transform: translateX(-50%);

            @media screen and (max-width: 414px) {
                width: 180px;
                height: 44px;
                top: -22px;
            }
        }

        &::after {
            content: "";
            display: block;
            width: 80px;
            height: 40px;
            margin: 0 auto;
            background: url(../img/lp/sample_deco.svg)center center /contain no-repeat;
            position: absolute;
            bottom: -43px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.box-lp-lesson-01 {
    border: 4px solid #ffccb6;
    border-radius: 20px;

    .box-title {
        background: #ffedda;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        padding: 27px 15px;

        img {
            margin: 0 auto;
        }
    }

    .box-inner {
        padding: 40px 50px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            padding: 20px 50px;
            display: block;
        }

        @media screen and (max-width: 414px) {
            padding: 20px;
        }

        .box-inner-list {
            width: 48%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            li {
                font-weight: 600;
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                font-size: 2.2rem;
                letter-spacing: 0.05em;
                font-feature-settings: "palt";
                position: relative;

                @media screen and (max-width: 768px) {
                    font-size: 1.8rem;
                }

                .inner {
                    position: relative;
                    line-height: 1.1;
                    padding-left: 1.5em;

                    &::before {
                        content: "\f0a9";
                        @include fa5;
                        color: #ff7e5a;
                        position: absolute;
                        left: 0;
                        top: 0.6em;
                        transform: translateY(-50%);
                        font-size: 1.8rem;

                        @media screen and (max-width: $tablet) {
                            font-size: 1.6rem;
                        }
                    }

                    .small {
                        font-size: 1.6rem;
                        vertical-align: middle;

                        @media screen and (max-width: 768px) {
                            font-size: 1.4rem;
                        }
                    }
                }

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 3px;
                    background: url(../img/lp/bg_06.svg);
                    background-repeat: repeat-x;
                    margin-bottom: 20px;
                    margin-top: 20px;

                    @media screen and (max-width: 414px) {
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

.box-lp-lesson-02 {
    width: 100%;
    height: auto;
    padding: 3px;
    background: linear-gradient(90deg, rgba(#fbab7e, 1) 0%, rgba(#f7ce68, 1) 100%);
    border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 20px;

    .box-inner {
        padding: 3px;
        background-color: #fff;
        border-radius: 9px;
        padding: 70px 95px 30px;
        position: relative;
        font-weight: 600;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;

        @media screen and (max-width: 768px) {
            padding: 70px 50px 30px;
        }

        @media screen and (max-width: 414px) {
            padding: 30px 20px 20px;
        }

        &::before {
            content: "";
            display: block;
            width: 446px;
            height: 94px;
            margin: 0 auto;
            background: url(../img/lp/title_deco_08.png)center center /contain no-repeat;
            position: absolute;
            left: 50%;
            top: -43px;
            transform: translateX(-50%);

            @media screen and (max-width: 425px) {
                width: 223px;
                height: 47px;
                top: -23px;
            }
        }

        .box-inner-list {
            &::before {
                content: "";
                display: block;
                width: 100%;
                height: 3px;
                background: url(../img/lp/bg_08.svg);
                background-repeat: repeat-x;
            }

            li {
                padding: 25px 0 0;

                @media screen and (max-width: 414px) {
                    padding: 15px 0px 0;
                }

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 3px;
                    background: url(../img/lp/bg_08.svg);
                    background-repeat: repeat-x;
                    margin-top: 25px;

                    @media screen and (max-width: 414px) {
                        margin-top: 15px;
                    }
                }

                .month {
                    background: #e6e6e6;
                    font-size: 2rem;
                    padding: 10px 25px;
                    letter-spacing: 0.05em;
                    margin-right: 30px;
                    border-radius: 20px;

                    @media screen and (max-width: 768px) {
                        font-size: 1.6rem;
                    }
                }

                .menu {
                    font-size: 2.4rem;
                    font-feature-settings: "palt";

                    @media screen and (max-width: 768px) {
                        font-size: 1.8rem;
                    }

                    @media screen and (max-width: 414px) {
                        line-height: 2;
                    }
                }
            }
        }

        .txt {
            color: #fc6238;
            text-align: center;
            font-size: 1.8rem;
            letter-spacing: 0.08em;
            font-feature-settings: "palt";

            @media screen and (max-width: 768px) {
                font-size: 1.5rem;
            }
        }
    }
}

.box-lp-attention-01 {
    background: #fff;
    padding: 30px 40px;

    .title {
        font-size: 2rem;
        font-weight: 600;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        letter-spacing: 0.1em;
        font-feature-settings: "palt";

        @media screen and (max-width: 768px) {
            font-size: 1.8rem;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 20px;
    }
}

.box-scimg-01 {
    overflow-x: scroll;

    img {
        max-width: none;
    }
}

//unit
.unit-lp-title-01 {
    font-weight: 600;
    font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
    font-size: 4.5rem;
    letter-spacing: 0.08em;
    text-align: center;
    font-feature-settings: "palt";

    @media screen and (max-width: 768px) {
        font-size: 3rem;
    }

    @media screen and (max-width: 414px) {
        font-size: 2.2rem;
    }

    &.flag {
        span {
            position: relative;

            &::before {
                content: "";
                display: inline-block;
                width: 119px;
                height: 67px;
                padding-right: 50px;
                background: url(../img/lp/title_flag_01.png)center center /contain no-repeat;

                @media screen and (max-width: 768px) {
                    position: absolute;
                    left: -150px;
                    top: -20px;
                    width: 80px;
                    height: 53px;
                }

                @media screen and (max-width: 414px) {
                    width: 60px;
                    height: 40px;
                    left: -110px;
                }
            }

            &::after {
                content: "";
                display: inline-block;
                width: 119px;
                height: 67px;
                padding-left: 50px;
                background: url(../img/lp/title_flag_02.png)center center /contain no-repeat;

                @media screen and (max-width: 768px) {
                    position: absolute;
                    right: -150px;
                    top: -20px;
                    width: 80px;
                    height: 53px;
                }

                @media screen and (max-width: 414px) {
                    width: 60px;
                    height: 40px;
                    right: -110px;
                }
            }
        }
    }
}

.unit-lp-btn-01 {
    a {
        position: relative;
        display: inline-block;
        background: rgb(247, 110, 80);
        background: linear-gradient(90deg, rgba(247, 110, 80, 1) 0%, rgba(255, 170, 70, 1) 100%);
        width: 660px;
        max-width: 100%;
        height: 80px;
        text-decoration: none;
        transition: color .25s ease;
        border-radius: 40px;
        overflow: hidden;
        color: #fff;
        font-weight: 600;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-size: 2.4rem;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

        @media screen and (max-width: 768px) {
            height: 70px;
            font-size: 2rem;
        }

        @media screen and (max-width: 414px) {
            font-size: 1.7rem;
        }

        &::before {
            position: absolute;
            content: "";
            display: block;
            background: #fff;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: rotateY(90deg);
            transform-origin: right;
            transition: transform .25s ease;
            border-radius: 40px;
        }

        span {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 4px 0 0;
            z-index: 1;

            .free {
                background: #fff;
                border-radius: 40px;
                color: #fa7864;
                font-size: 1.5rem;
                padding: 3px 15px;
                display: inline-block;
                width: auto;
                height: auto;
                margin-right: 20px;
                letter-spacing: 0.08em;

                @media screen and (max-width: 414px) {
                    font-size: 1.3rem;
                    margin-right: 10px;
                    padding: 3px 10px;
                    letter-spacing: 0;
                }
            }
        }

        &:hover {
            opacity: 1;
            color: #f7931e;
            border: 1px solid #f7931e;

            &::before {
                transform: rotateY(0);
                transform-origin: left;
            }

            span {
                .free {
                    background: rgb(247, 110, 80);
                    background: linear-gradient(90deg, rgba(247, 110, 80, 1) 0%, rgba(255, 170, 70, 1) 100%);
                    color: #fff;
                }
            }
        }
    }

    &.middle {
        a {
            width: 400px;
            font-size: 2rem;
            height: 70px;

            @media screen and (max-width: 768px) {
                font-size: 1.8rem;
            }
        }
    }
}

.unit-lp-btn-02 {
    .btn-inner {
        position: relative;
        z-index: 1;
        text-align: center;
        width: 100%;
        max-width: 380px;
        padding: 12px 30px;
        border: 1px solid #1b96f3;
        color: #1b96f3;
        letter-spacing: 0.1em;
        font-weight: bold;
        border-radius: 9999px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 100%;
            height: 100%;
            transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            border-radius: 9999px;
            background: #1b96f3;
            border: 1px solid #1b96f3;
        }

        &.btn-white {
            &:not(:hover) {
                border-color: #fff;
                color: #fff;
            }
        }

        &:hover {
            opacity: 1;
            color: #fff;
        }

        &:not(:hover) {
            &::after {
                width: 0;
                height: 0;
                opacity: 0;
            }
        }
    }

    &.black {
        .btn-inner {
            color: #333;
            border: 2px solid #333;

            &::after {
                background: #333;
                border: 2px solid #333;
            }

            &:hover {
                color: #fff;
            }
        }
    }
}

.unit-lp-price-01 {
    width: 330px;
    text-align: center;
    border-radius: 10px;
    background: #fff;

    @include max-screen($tablet) {
        width: 100%;
    }

    .title {
        width: 100%;
        background: rgb(141, 235, 179);
        background: linear-gradient(90deg, rgba(141, 235, 179, 1) 0%, rgba(197, 241, 133, 1) 100%);
        font-size: 2.8rem;
        font-weight: 600;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        letter-spacing: 0.08em;
        font-feature-settings: "palt";
        height: 124px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        line-height: 1.29;

        @include max-screen($tablet) {
            height: auto;
            padding: 15px;
            font-size: 2.2rem;
        }

        @include max-screen($mobile) {
            font-size: 1.8rem;
        }
    }

    .txt-box {
        padding: 30px;
        background: #fff;

        @media screen and (max-width: 768px) {
            border-radius: 10px;
            padding: 20px;
        }

        .price {
            font-size: 5.6rem;
            line-height: 1.2;
            letter-spacing: 0.05em;
            font-weight: bold;
            @include barlow;
            letter-spacing: -0.02em;
            color: #00b478;

            @include max-screen($tablet) {
                font-size: 4.2rem;
            }

            img {
                text-align: center;
                margin: 0 auto;
            }

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 3px;
                background: url(../img/lp/bg_08.svg);
                background-repeat: repeat-x;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        .num {
            padding-right: 5px;
        }

        .yen {
            margin-left: 5px;
        }

        .yen,
        .num {
            font-size: 3rem;
            font-weight: 600;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;

            @include max-screen($tablet) {
                font-size: 2.6rem;
            }

            @include max-screen($mobile) {
                font-size: 2.3rem;
            }
        }

        .inner-list-01 {
            li {
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                font-weight: 600;
                font-size: 1.7rem;
                text-align: left;
                padding-left: 1.2em;
                text-indent: -0em;
                position: relative;
                line-height: 1.444;

                @media screen and (max-width: 768px) {
                    font-size: 1.6rem;
                }

                &::before {
                    content: "\f058";
                    @include fa5;
                    color: #00b478;
                    font-size: 1.4rem;
                    position: absolute;
                    left: 0;
                    top: 0.2em;
                }
            }
        }

        .inner-list-02 {
            margin-top: 20px;

            li {
                font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
                font-weight: 300;
                font-size: 1.4rem;
                text-align: left;
                padding-left: 1em;
                text-indent: -0em;
                position: relative;
                line-height: 1.57;

                &::before {
                    content: "・";
                    font-size: 1.2rem;
                    position: absolute;
                    left: 0;
                    top: 0.2em;
                }
            }
        }
    }

    &.deco {
        border: 5px solid #00b478;
        position: relative;
        background: #fff;

        .title {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            position: relative;
            padding-top: 10px;

            &::before {
                content: "";
                width: 41px;
                height: 44px;
                background: url(../img/lp/price_deco_02.png)center center /contain no-repeat;
                display: block;
                position: absolute;
                left: 10px;
                top: 35px;

                @media screen and (max-width: 414px) {
                    width: 20px;
                    height: 22px;
                    left: 30px;
                }
            }

            &::after {
                content: "";
                width: 41px;
                height: 44px;
                background: url(../img/lp/price_deco_02.png)center center /contain no-repeat;
                display: block;
                position: absolute;
                top: 10px;
                right: 10px;

                @media screen and (max-width: 414px) {
                    width: 20px;
                    height: 22px;
                    right: 30px;
                }
            }
        }

        &::before {
            content: "";
            width: 215px;
            height: 102px;
            background: url(../img/lp/price_deco_01.png)center center /contain no-repeat;
            display: block;
            position: absolute;
            left: 50%;
            top: -80px;
            transform: translateX(-58%);
            z-index: 2;

            @media screen and (max-width: 768px) {
                content: "１番人気";
                font-weight: bold;
                background: #00b478;
                left: -2px;
                top: -2px;
                border-bottom-right-radius: 10px;
                transform: none;
                width: auto;
                height: auto;
                color: #fff;
                padding: 5px 10px;
            }
        }

        &::after {
            content: "";
            width: 83px;
            height: 90px;
            background: url(../img/lp/price_deco_03.png)center center /contain no-repeat;
            display: block;
            position: absolute;
            bottom: -10px;
            right: -3px;
        }
    }
}

.unit-lp-price-02 {
    width: 330px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.06);

    @include max-screen($tablet) {
        width: 100%;
    }

    .title {
        width: 100%;
        background: rgb(141, 235, 179);
        background: linear-gradient(90deg, rgba(141, 235, 179, 1) 0%, rgba(197, 241, 133, 1) 100%);
        font-size: 2.8rem;
        font-weight: 600;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        letter-spacing: 0.08em;
        font-feature-settings: "palt";
        height: 124px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        line-height: 1.29;

        @include max-screen($tablet) {
            height: auto;
            padding: 15px;
            font-size: 2.2rem;
        }

        @include max-screen($mobile) {
            font-size: 1.8rem;
        }
    }

    .txt-box {
        padding: 30px;
        background: #fff;

        @media screen and (max-width: 768px) {
            border-radius: 10px;
            padding: 20px;
        }

        .price {
            font-size: 5.6rem;
            line-height: 1.2;
            letter-spacing: 0.05em;
            font-weight: bold;
            @include barlow;
            letter-spacing: -0.02em;
            color: #00b478;

            @include max-screen($tablet) {
                font-size: 4.2rem;
            }

            img {
                text-align: center;
                margin: 0 auto;
            }

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 3px;
                background: url(../img/lp/bg_08.svg);
                background-repeat: repeat-x;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }

        .p-text {
            font-weight: 600;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
            font-size: 2.2rem;
            letter-spacing: 0.05em;
            color: #f62459;

            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 1.6rem;
                letter-spacing: 0;
            }
        }

        .num {
            padding-right: 5px;
        }

        .yen {
            margin-left: 5px;
        }

        .yen,
        .num {
            font-size: 3rem;
            font-weight: 600;
            font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;

            @include max-screen($tablet) {
                font-size: 2.6rem;
            }

            @include max-screen($mobile) {
                font-size: 2.3rem;
            }
        }

        .inner-list-01 {
            li {
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                font-weight: 600;
                font-size: 1.7rem;
                text-align: left;
                padding-left: 1.2em;
                text-indent: -0em;
                position: relative;

                @media screen and (max-width: 768px) {
                    font-size: 1.6rem;
                }

                &::before {
                    content: "\f058";
                    @include fa5;
                    color: #00b478;
                    font-size: 1.4rem;
                    position: absolute;
                    left: 0;
                    top: 0.2em;
                }
            }
        }
    }

    &.osusume {
        border: 5px solid #00b478;
        position: relative;
        background: #fff;

        .title {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            position: relative;
            padding-top: 10px;
        }

        &::before {
            content: "";
            width: 181px;
            height: 42px;
            background: url(../img/lp/about/price_deco_01.png)center center /contain no-repeat;
            display: block;
            position: absolute;
            left: 50%;
            top: -20px;
            transform: translateX(-50%);
            z-index: 2;

            @media screen and (max-width: 768px) {
                content: "おすすめ";
                font-weight: bold;
                background: #00b478;
                left: -2px;
                top: -2px;
                border-bottom-right-radius: 10px;
                transform: none;
                width: auto;
                height: auto;
                color: #fff;
                padding: 5px 10px;
            }
        }
    }
}

.unit-lp-voice-01 {
    > .voice-inner {
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        padding: 40px;
        border-radius: 10px;

        @media screen and (max-width: $tablet) {
            padding: 20px;
        }

        p {
            margin: 0;
        }

        > .thum {
            width: 50px;
            margin-right: 20px;

            @media screen and (max-width: $tablet) {
                margin-right: 15px;
            }
        }

        > .txt {
            flex: 1;
            letter-spacing: 0.05em;
            font-feature-settings: "palt";

            .main {
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                font-weight: 600;
                font-size: 2.4rem;

                @media screen and (max-width: $tablet) {
                    font-size: 2rem;
                }

                + .sub {
                    margin-top: 10px;
                }
            }

            .sub {
                font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
                font-weight: 300;
            }

            .person {
                color: #ff7e5a;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
                font-weight: 600;
                margin-top: 20px;

                @media screen and (max-width: 768px) {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

.unit-lp-faq-01 {
    background-color: #fff;
    padding: 30px 65px 30px 30px;
    position: relative;
    margin: 0 auto 20px;
    max-width: 900px;

    @media screen and (max-width: 768px) {
        padding: 5px 40px 10px 20px;
        margin: 0 auto 10px;
    }

    .q {
        padding: 10px 0 10px 50px;
        font-size: 1.8rem;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-weight: 600;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            font-size: 1.6rem;
            padding: 10px 0 10px 35px;
        }

        &::before {
            @include poppins;
            content: "Q";
            color: #fff;
            left: 30px;
            font-weight: 600;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            background: #00b0ba;
            position: absolute;
            text-align: center;
            font-size: 1.6rem;

            @media screen and (max-width: 768px) {
                top: 14px;
                left: 20px;
                width: 25px;
                height: 25px;
                line-height: 25px;
                border-radius: 25px;
                font-size: 1.3rem;
            }
        }

        .icon {
            &::before {
                content: "\f067";
                font-family: FontAwesome;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.2rem;
                color: #4d4d4d;
                z-index: 1;
                position: absolute;
            }
        }

        &.active {
            .icon {
                &::before {
                    content: "\f068";
                }
            }
        }
    }

    .a {
        position: relative;
        border-top: 2px dashed #c7c7c7;
        margin: 20px 0 0;
        padding: 30px 0 10px 50px;
        display: none;
        font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
        font-weight: 300;

        @media screen and (max-width: 768px) {
            padding: 10px 0 0 35px;
            margin-top: 5px;
        }

        &::before {
            @include poppins;
            content: "A";
            color: #fff;
            background-color: #fc6238;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            position: absolute;
            font-weight: 600;
            left: 0;
            text-align: center;
            font-size: 1.6rem;

            @media screen and (max-width: 768px) {
                width: 25px;
                height: 25px;
                line-height: 25px;
                border-radius: 25px;
                font-size: 1.3rem;
                left: 0;
                top: 12px;
            }
        }

        .title-a {
            margin-bottom: 0;
            font-weight: 500;
            padding-top: 3px;

            @media screen and (max-width: 768px) {
                padding-top: 5px;
                margin-bottom: 5px;
            }
        }

        p {
            margin-bottom: 20px;
        }
    }
}

.unit-fixed-btn-01 {
    position: fixed;
    z-index: 50;
    bottom: 100px;
    right: 30px;

    @media screen and (max-width: 768px) {
        display: none;
    }

    .btn-inner {
        display: block;
        height: 141px;
        width: 141px;
        background: url(../img/lp/fixed_img_01.png) center center/contain no-repeat;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
        border-radius: 100%;

        & > * {
            font-size: 0;
        }

        &:hover {
            animation: buru 0.2s 1;
        }
    }
}

.unit-lp-welcome-01 {
    width: 340px;
    height: auto;
    padding: 3px;
    text-align: center;
    background: linear-gradient(0deg, rgba(197, 241, 133, 1) 0%, rgba(141, 235, 179, 1) 100%);
    border-radius: 10px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        text-align: left;
        margin-bottom: 0;
    }

    .box-inner {
        padding: 3px;
        background-color: #FFF;
        border-radius: 9px;
        padding: 30px 0px 70px;
        font-size: 2.2rem;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-weight: 600;
        line-height: 1.64;
        position: relative;

        @media screen and (max-width: 768px) {
            font-size: 1.8rem;
            padding: 30px 60px 30px 30px;
        }

        @media screen and (max-width: 414px) {
            padding: 15px 60px 15px 15px;
        }

        figure {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);

            @media screen and (max-width: 768px) {
                width: 60px;
                height: 60px;
                right: 15px;
                left: inherit;
                top: 50%;
                transform: translateY(-50%);

                img {
                    max-width: 100%;
                }
            }

            @media screen and (max-width: 414px) {
                width: 45px;
                height: 45px;
                right: 10px;
            }
        }
    }
}

.unit-lp-text-01 {
    font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2;
    letter-spacing: 0.05em;

    .fw-bold {
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-weight: 600;
    }

    @media screen and (max-width: 768px) {
        font-size: 1.6rem;
    }
}

.unit-lp-link-01 {
    color: #1b96f3 !important;
    text-decoration: underline;
}

.unit-lp-marker-01 {
    background: linear-gradient(transparent 50%, #cff800 50%);
    display: inline-block;
    line-height: 1.2;
}

.unit-lp-rec-01 {
    background: #fff;
    width: 400px;
    max-width: 100%;
    border-radius: 30px;
    font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
    font-weight: 600;
    font-size: 1.8rem;
    padding: 15px 0;
    margin-bottom: 40px;
    position: relative;
    letter-spacing: 0.05em;
    font-feature-settings: "palt";

    @media screen and (max-width: 768px) {
        font-size: 1.6rem;
        letter-spacing: 0.0em;
        width: auto;
        margin-bottom: 10px;
        min-height: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
    }

    @media screen and (max-width: 414px) {
        font-size: 1.3rem;
    }

    @media screen and (min-width: 769px) {
        &.left {
            &::before {
                content: "";
                background: url(../img/lp/rec_comment_left.png)center center / contain no-repeat;
                width: 64px;
                height: 35px;
                display: block;
                position: absolute;
                bottom: -24px;
                right: 45px;
            }
        }

        &.right {
            &::before {
                content: "";
                background: url(../img/lp/rec_comment_right.png)center center / contain no-repeat;
                width: 64px;
                height: 35px;
                display: block;
                position: absolute;
                bottom: -24px;
                left: 45px;
            }
        }
    }
}

.unit-lp-sample-01 {
    img {
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
        width: 100%;
    }
}

.unit-lp-attention-01 {
    li {
        font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
        font-weight: 300;
        font-size: 1.4rem;
        padding-left: 1em;
        text-indent: -0em;
        position: relative;
        font-feature-settings: "palt";

        &::before {
            content: "※";
            font-size: 1.2rem;
            position: absolute;
            left: 0;
            top: 0.2em;
        }
    }
}

.unit-lp-subtext-01 {
    font-family: "FP-ヒラギノ角ゴ ProN W3", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    letter-spacing: 0.08em;

    @media screen and (max-width: 414px) {
        font-size: 1.3rem;
    }
}

.unit-lp-textbox-01 {
    background: #fff;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;

    p {
        text-align: center;
        font-family: "FP-ヒラギノ角ゴ ProN W6", sans-serif;
        font-weight: 600;
        font-size: 2rem;
        line-height: 1.8;

        @media screen and (max-width: 768px) {
            font-size: 1.8rem;
        }

        @media screen and (max-width: 414px) {
            font-size: 1.5rem;
        }

        img {
            margin: 0 auto;
        }
    }
}
