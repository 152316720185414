// 0.0.0
@charset "UTF-8";

.box-blogdetail-01{
    >*{
        margin-top: 20px;
        @include max-screen($tablet){
            margin-top: 15px;
        }
        @include max-screen($mobile){
            margin-top: 10px;
        }
        &:first-child{
            margin-top: 0;
        }
    }
    h1,h2,h3{
        margin-top: 40px;
        @include max-screen($tablet){
            margin-top: 35px;
        }
        @include max-screen($mobile){
            margin-top: 30px;
        }
        &:first-child{
            margin-top: 0;
        }
        +h2,+h3,+h4,+h5,+h6{
            margin-top: 20px;
            @include max-screen($tablet){
                margin-top: 15px;
            }
            @include max-screen($mobile){
                margin-top: 10px;
            }
        }
        +table,
        +p{
            margin-top: 10px;
            @include max-screen($tablet){
                margin-top: 8px;
            }
            @include max-screen($mobile){
                margin-top: 5px;
            }
        }
    }
    h4,h5,h6{
        margin-top: 30px;
        @include max-screen($tablet){
            margin-top: 25px;
        }
        @include max-screen($mobile){
            margin-top: 20px;
        }
        &:first-child{
            margin-top: 0;
        }
        +table,
        +p{
            margin-top: 10px;
            @include max-screen($tablet){
                margin-top: 8px;
            }
            @include max-screen($mobile){
                margin-top: 5px;
            }
        }
    }
    h2{
        margin-bottom: 20px;
        padding-bottom: 19px;
        border-bottom: 1px solid #D6D6D6;
        font-size: 2.2rem;
        letter-spacing: 0.07em;
        margin-top: 75px;
        @include max-screen($tablet){
            margin-top: 60px;
            margin-bottom: 20px;
            padding-bottom: 15px;
            font-size: 2.0rem;
        }
        @include max-screen($mobile){
            margin-top: 40px;
            margin-bottom: 20px;
            padding-bottom: 10px;
            font-size: 1.8rem;
        }
        &:first-child{
            margin-top: 0;
        }
    }
    h3{
        font-size: 2.0rem;
        @include max-screen($tablet){
            font-size: 1.8rem;
        }
        @include max-screen($mobile){
            font-size: 1.6rem;
        }
    }
    h4{
        font-size: 1.8rem;
        @include max-screen($tablet){
            font-size: 1.7rem;
        }
        @include max-screen($mobile){
            font-size: 1.5rem;
        }
    }
    h5{
        font-size: 1.7rem;
        @include max-screen($tablet){
            font-size: 1.6rem;
        }
        @include max-screen($mobile){
            font-size: 1.5rem;
        }
    }
    h6{
        font-size: 1.6rem;
        @include max-screen($tablet){
            font-size: 1.5rem;
        }
        @include max-screen($mobile){
            font-size: 1.4rem;
        }
    }
    ul{
        li{
            position: relative;
            margin-top: 5px;
            padding-left: 1em;
            &:before{
                content: "・";
                margin-left: -1em;
            }
            &:first-child{
                margin-top: 0;
            }
            a{
                color: #0071BC;
                text-decoration: underline;
            }
        }
    }
    p{
        @include cf;
    }
    a{
        color: $link_c;
        text-decoration: underline;
    }
    strong{
        font-weight: bold;
    }
    img{
        margin-left: auto;
        margin-right: auto;
        @include max-screen($tablet){
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        @include max-screen($mobile){
            margin-top: 15px;
            margin-bottom: 15px;
        }
        &.alignright{
            margin-left: 50px;
            @include max-screen($tablet){
                margin-left: 30px;
            }
            @include max-screen($mobile){
                margin: 10px auto;
            }
        }
        &.alignleft{
            margin-right: 50px;
            @include max-screen($tablet){
                margin-right: 30px;
            }
            @include max-screen($mobile){
                margin: 10px auto;
            }
        }
        &.aligncenter{
        }
    }
    hr{
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
        border:none;
        border-top:1px solid #E7E7EB;
    }
    iframe{
        width: 100% !important;
    }
    table{
        margin-top: 40px;
        @include max-screen($tablet){
            margin-top: 30px;
        }
        @include max-screen($mobile){
            margin-top: 25px;
        }
        &:first-child{
            margin-top: 0;
        }
    }
    figure{
        figcaption{
            padding: 5px 10px;
        }
    }
    .box-colomn{
        .colomn-inner{
            figure{
                img{
                    width: 100%;
                }
            }
        }
    }
    
    .alignright{
        float: right;
        @include max-screen($mobile){
            float: none;
        }
    }
    .alignleft{
        float: left;
        @include max-screen($mobile){
            float: none;
        }
    }
    .aligncenter{
        margin-right: auto;
        margin-left: auto;
    }
    
    ol{
        @extend .unit-numlist-01;
    }
}
