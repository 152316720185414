@charset "UTF-8";

.box-header-01 {
    @media screen and (min-width: 769px) {
        height: 80px;

        .header-wrap {
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            width: 100%;
            background: #fff;
            box-shadow: 2px 0 2px rgba(#000, 0.2);

            .header-inner {
                display: flex;
                justify-content: space-between;
                height: 80px;

                .header-logo {
                    display: flex;
                    align-items: center;
                    padding-left: 15px;
                    padding-right: 15px;

                    .logo-inner {
                        display: block;

                        & > span {
                            display: block;
                            height: 44px;
                            width: 193px;
                            background: url(../img/common/logo.svg) left center/contain no-repeat;
                            color: transparent;
                        }
                    }
                }

                .header-nav {
                    .nav-inner {
                        display: flex;

                        & > ul {
                            display: flex;

                            & > li {
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.1em;

                                & > a {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 0 10px;
                                    height: 80px;
                                    font-weight: bold;
                                    @include smooth;
                                    white-space: nowrap;
                                    font-size: 1.4rem;

                                    &:hover {
                                        opacity: 1;
                                        color: $base_c;
                                    }

                                    &.nav-parent {
                                        pointer-events: none;
                                    }
                                }

                                & > ul {
                                    position: fixed;
                                    overflow: hidden;
                                    z-index: 101;
                                    top: 80px;
                                    width: 200px;
                                    color: #000;
                                    transition: all 0.5s ease-in-out;
                                    max-height: 300px;
                                    border: 1px solid #ebeae8;

                                    & > li {
                                        padding: 15px 15px;
                                        font-size: 1.5rem;
                                        background: #fff;
                                        transition: all 0.3s ease-in-out;

                                        &:hover {
                                            opacity: 1;
                                            background: #e3edd7;
                                        }

                                        &:not(:first-child) {
                                            border-top: 1px solid #ebeae8;
                                        }
                                    }
                                }

                                &:not(:hover) {
                                    & > ul {
                                        opacity: 0;
                                        pointer-events: none;
                                        height: 0;
                                        padding: 0;
                                    }
                                }
                            }
                        }

                        .nav-tel {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0 15px;
                        }

                        .nav-btn {
                            display: flex;
                            width: 200px;

                            @media screen and (max-width: 1100px) {
                                position: fixed;
                                top: 80px;
                                right: 0;
                            }

                            .btn-inner {
                                position: relative;
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                width: 50%;
                                height: 80px;
                                background: $base_c;
                                font-size: 1.5rem;
                                font-weight: bold;
                                letter-spacing: 0.1em;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                color: #fff;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    background: rgba(#660000, 0.2);
                                    transition: all 0.5s ease;
                                    transform-origin: bottom right;
                                }

                                & > * {
                                    position: relative;
                                    z-index: 1;
                                }

                                &.btn-reserve {
                                    background: $link_c;
                                }

                                &:hover {
                                    opacity: 1;
                                }

                                &:not(:hover) {
                                    &::after {
                                        opacity: 0;
                                        width: 0;
                                        transform: skew(90deg);
                                    }
                                }
                            }
                        }
                    }
                }

                .header-btn {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 50px;

        .header-wrap {
            .header-inner {
                .header-logo {
                    position: fixed;
                    z-index: 100;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 50px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    transition: all 0.5s ease;

                    & > * {
                        width: calc(100% - 180px);

                        .logo-inner {
                            display: block;

                            & > span {
                                display: block;
                                width: 100%;
                                height: 35px;
                                background: url(../img/common/logo.svg) left center/contain no-repeat;
                                font-size: 0;
                            }
                        }
                    }
                }

                .header-nav {
                    .nav-inner {
                        position: fixed;
                        z-index: 101;
                        top: 50px;
                        left: 0;
                        overflow-y: scroll;
                        width: 100%;
                        max-height: calc(100vh - 50px);
                        background: #f5f8f1;
                        transition: all 0.5s ease;
                        box-shadow: 0 0 15px rgba(#000, 0.1);

                        & > ul {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 15px 30px;

                            & > li {
                                position: relative;
                                width: 100%;
                                border-bottom: 2px solid #fff;

                                & > a {
                                    display: block;
                                    padding: 10px 0;
                                    letter-spacing: 0.1em;
                                    font-size: 1.5rem;
                                    font-weight: bold;

                                    &::after {
                                        content: "\f105";
                                        position: absolute;
                                        top: 50%;
                                        right: 15px;
                                        transform: translateY(-50%);
                                        font-family: fontawesome;
                                        color: #000;
                                    }
                                }

                                & > ul {
                                    display: none;
                                }
                            }
                        }

                        .nav-btn {
                            padding: 15px 15px 40px;

                            .btn-inner {
                                display: block;
                                max-width: 280px;
                                margin-left: auto;
                                margin-right: auto;
                                padding: 10px;
                                border-radius: 100px;
                                text-align: center;
                                background: $base_c;
                                letter-spacing: 0.1em;
                                font-size: 1.4rem;
                                font-weight: bold;
                                color: #fff;

                                & + .btn-inner {
                                    margin-top: 10px;
                                }

                                &.btn-reserve {
                                    background: $link_c;
                                }
                            }
                        }
                    }
                }
            }

            .header-btn {
                position: fixed;
                z-index: 101;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;

                .btn-btn {
                    .btn-inner {
                        text-align: center;
                        padding: 5px 15px;
                        background: $link_c;
                        font-weight: bold;
                        letter-spacing: 0.1em;
                        font-size: 1.1rem;
                        color: #fff;
                        border-radius: 9999px;
                    }
                }

                .btn-bar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    width: 50px;
                    height: 50px;
                    background: #fff;

                    .bar-inner {
                        &::after {
                            display: block;
                            font-size: 1rem;
                            line-height: 1;
                            margin: 5px 0 -2px;
                            color: #fff;
                        }

                        & > * {
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 25px;
                            height: 2px;
                            background: #000;
                            transition: all 0.5s ease;

                            &:not(:last-child) {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }
            }
        }

        &.is-open {
            .header-wrap {
                .header-nav {
                    .nav-logo {
                        background: #fff;
                    }
                }

                .header-btn {
                    .btn-bar {
                        .bar-inner {
                            &::after {}

                            .bar1 {
                                transform: translateY(7px) rotate(45deg);
                            }

                            .bar2 {
                                transform: scaleX(0);
                            }

                            .bar3 {
                                transform: translateY(-7px) rotate(-45deg);
                            }
                        }
                    }
                }
            }
        }

        &:not(.is-open) {
            .header-wrap {
                .header-inner {
                    .header-nav {
                        .nav-inner {
                            opacity: 0;
                            max-height: 0;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}

.box-header-02 {
    @media screen and (min-width: 769px) {
        height: 100px;

        .header-wrap {
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            width: 100%;
            background: #fff;
            box-shadow: 0 0 15px rgba(#000, 0.1);

            @media screen and (max-width: 1100px) {
                width: 1100px;
            }

            .header-inner {
                display: flex;
                justify-content: space-between;
                height: 100px;

                .header-logo {
                    display: flex;
                    align-items: center;
                    padding-left: 15px;

                    .logo-inner {
                        display: block;

                        & > span {
                            display: block;
                            height: 26px;
                            width: 345px;
                            background: url(../img/lp/lp_logo.png) center center/contain no-repeat;
                            font-size: 0;
                        }
                    }
                }

                .header-nav {
                    .nav-inner {
                        display: flex;
                        padding-right: 55px;

                        & > ul {
                            display: flex;

                            & > li {
                                display: flex;
                                align-items: center;
                                letter-spacing: 0.1em;

                                &:not(:first-child) {
                                    &::before {
                                        content: "";
                                        display: block;
                                        height: 15px;
                                        width: 1px;
                                        background: #fff;
                                        margin: 0 20px;
                                    }
                                }

                                & > a {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 100px;
                                    white-space: nowrap;
                                    letter-spacing: 0.1em;
                                    font-family: "FP-ヒラギノ角ゴ ProN W6";
                                    font-weight: 600;
                                    font-size: 1.5rem;
                                    color: #4d4d4d;
                                }
                            }
                        }

                        .nav-btn {
                            .btn-inner {
                                position: relative;
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 180px;
                                height: 100px;
                                margin-left: 50px;
                                background: #898989;
                                font-size: 1.8rem;
                                font-weight: bold;
                                letter-spacing: 0.1em;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                color: #fff;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    background: #aaa;
                                    transition: all 0.5s ease;
                                    transform-origin: bottom right;
                                }

                                & > * {
                                    position: relative;
                                    z-index: 1;
                                }

                                &:hover {
                                    opacity: 1;
                                }

                                &:not(:hover) {
                                    &::after {
                                        opacity: 0;
                                        width: 0;
                                        transform: skew(90deg);
                                    }
                                }
                            }
                        }
                    }
                }

                .header-btn {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        height: 50px;

        .header-wrap {
            .header-inner {
                .header-logo {
                    position: fixed;
                    z-index: 100;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 50px;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    transition: all 0.5s ease;
                    box-shadow: 0 0 15px rgba(#000, 0.1);

                    & > * {
                        width: calc(100% - 150px);

                        .logo-inner {
                            display: block;

                            & > span {
                                display: block;
                                height: 50px;
                                width: 220px;
                                background: url(../img/lp/lp_logo.png) center center/contain no-repeat;
                                font-size: 0;

                                @media screen and (max-width: 414px) {
                                    width: 190px;
                                }
                            }
                        }
                    }
                }

                .header-nav {
                    .nav-inner {
                        position: fixed;
                        z-index: 101;
                        top: 50px;
                        left: 0;
                        overflow-y: scroll;
                        width: 100%;
                        max-height: calc(100vh - 50px);
                        background: #f5f5f5;
                        transition: all 0.5s ease;
                        box-shadow: 0 0 15px rgba(#000, 0.1);
                        padding-bottom: 20px;

                        & > ul {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 15px;

                            & > li {
                                position: relative;
                                width: 100%;
                                border-bottom: 1px solid #fff;

                                & > a {
                                    display: block;
                                    padding: 12.5px 0;
                                    letter-spacing: 0.1em;
                                    font-size: 1.8rem;
                                    font-weight: 600;
                                    font-family: "FP-ヒラギノ角ゴ ProN W6";
                                }

                                & > ul {
                                    display: none;
                                }
                            }
                        }

                        .nav-btn {
                            padding: 15px 15px 40px;

                            .btn-inner {
                                display: block;
                                max-width: 280px;
                                margin-left: auto;
                                margin-right: auto;
                                padding: 12.5px;
                                border-radius: 100px;
                                text-align: center;
                                background: #898989;
                                letter-spacing: 0.1em;
                                font-size: 1.6rem;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .header-btn {
                position: fixed;
                z-index: 101;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;

                .unit-lp-btn-01 {
                    a {
                        width: 130px;
                        height: 40px;
                        font-size: 1.4rem;
                        vertical-align: bottom;

                        @media screen and (max-width: 414px) {
                            height: 30px;
                            width: 100px;
                            font-size: 1.2rem;
                        }
                    }
                }

                .btn-bar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    width: 50px;
                    height: 50px;
                    background: #fff;

                    .bar-inner {
                        & > * {
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 25px;
                            height: 2px;
                            background: #000;
                            transition: all 0.5s ease;

                            &:not(:last-child) {
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
        }

        &.is-open {
            .header-wrap {
                .header-nav {
                    .nav-logo {
                        background: #969696;
                    }
                }

                .header-btn {
                    .btn-bar {
                        .bar-inner {

                            .bar1 {
                                transform: translateY(10px) rotate(45deg);
                            }

                            .bar2 {
                                transform: scaleX(0);
                            }

                            .bar3 {
                                transform: translateY(-10px) rotate(-45deg);
                            }
                        }
                    }
                }
            }
        }

        &:not(.is-open) {
            .header-wrap {
                .header-inner {
                    .header-nav {
                        .nav-inner {
                            opacity: 0;
                            max-height: 0;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
}
