@charset "UTF-8";

.box-footer-01 {
    overflow: hidden;
    position: relative;
    display: block;

    .footer-sitemap {
        @media screen and (min-width: 769px) {
            padding: 60px 0;

            .box-sitemap-01 {
                //width: 95%;
            }
        }

        .wrapper {
            @media screen and (max-width: 768px) {
                padding: 0;
            }
        }
    }

    .footer-copyright {
        padding: 30px 0;
        text-align: center;

        @media screen and (max-width: 768px) {
            padding: 45px 0 15px;
        }

        small {
            display: block;
            margin-top: 30px;
            color: #a4a4a4;
            font-size: 1.1rem;
            letter-spacing: 0.1em;
        }
    }
}

.box-footer-02 {
    padding: 60px 0px 25px;

    @media screen and (max-width: 768px) {
        padding: 20px 0;
    }

    .footer-sns {
        .unit-sns-01 {
            justify-content: center;
            color: #999;

            *[class*="sns-"] {
                font-size: 0;
                line-height: 1;

                &::before {
                    @include fa5-bra;
                    font-size: 30px;

                    @media screen and (max-width: 768px) {
                        font-size: 22px;
                    }
                }
            }
        }
    }

    .footer-copyright {
        text-align: center;
        color: #999;
        margin-top: 20px;
        font-size: 1.1rem;

        @media screen and (max-width: 768px) {
            margin-top: 10px;
        }
    }
}
