@charset "UTF-8";

.unit-text-01,
.unit-text-02 {
    letter-spacing: 0.05em;

    & > * {
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }

    img {
        margin: 0 auto;
        width: 100%;
    }
}

.unit-text-01 {}

.unit-text-02 {
    @media screen and (min-width: 769px) {
        text-align: center;
    }
}

.unit-text-03 {
    letter-spacing: 0.1em;
    font-weight: 500;
    @include yugo;

    & > * {
        &:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}

.unit-text-kome-01 {
  display: flex;

  &::before {
    content: '※';
  }
}

.unit-tel-01 {
    cursor: text;
    color: #666;

    .tel-inner {
        @include poppins;
        font-size: 1.8rem;
        color: #666;
        white-space: nowrap;
        letter-spacing: 0.05em;

        @media screen and (min-width: 769px) {
            pointer-events: none;
        }

        &::before {
            content: "\f095";
            @include fa5;
            margin-right: 0.5em;
        }
    }

    .tel-txt {
        font-size: 1.2rem;
        letter-spacing: 0.1em;
    }
}

.unit-tel-02 {
    text-align: center;
    cursor: text;

    .tel-inner {
        font-size: 5rem;
        @include poppins;
        letter-spacing: 0.05em;
        @include smooth;
        white-space: nowrap;

        @media screen and (max-width: 768px) {
            font-size: 3rem;
        }

        &::before {
            content: "tel.";
            font-size: 0.75em;
        }

        & + * {
            margin-top: 5px;
        }

        @media screen and (min-width: 769px) {
            pointer-events: none;
        }
    }

    .tel-txt {
        text-align: center;
        letter-spacing: 0.1em;
        font-size: 0.9375em;
        font-weight: bold;
        @include smooth;
    }
}

.unit-title-01 {
    position: relative;
    z-index: 1;
    font-size: 3.6rem;
    font-weight: bold;
    letter-spacing: 0.1em;

    @media screen and (max-width: 768px) {
        font-size: 2rem;
    }

    .title-deco {
        display: inline-block;
        color: $base_c;

        &::before,
        &::after {
            content: "";
            display: inline-block;
            vertical-align: top;
            height: 30px;
            width: 20px;
            background: url(../img/common/deco_note_01.svg) center center/contain no-repeat;
            margin: 0 1em;
        }

        &::after {
            height: 25px;
            vertical-align: middle;
            margin: 0 0.5em;
            background-image: url(../img/common/deco_note_02.svg);
        }
    }

    &.fs30 {
        font-size: 3rem;

        @media screen and (max-width: 768px) {
            font-size: 2rem;
        }

        @media screen and (max-width: 414px) {
            font-size: 5vw;
        }
    }

    &[class*="txt-"] {
        &::after {
            content: "";
            position: relative;
            z-index: -1;
            display: block;
            width: 100%;
            height: 100px;
            margin-top: -60px;
            background: url(../img/common/txt_about_01.svg) center bottom/auto 100% no-repeat;

            @media screen and (max-width: 768px) {
                height: 50px;
                margin-top: -30px;
            }
        }
    }

    &.txt-about {
        margin-bottom: 50px;

        @media screen and (max-width: 768px) {
            margin-bottom: 20px;
        }

        &::after {
            background-image: url(../img/common/txt_about_01.svg);
        }
    }

    &.txt-curriculum {
        &::after {
            background-image: url(../img/common/txt_curriculum_01.svg);
        }
    }

    &.txt-voice {
        &::after {
            height: 120px;
            margin-top: -70px;
            background-image: url(../img/common/txt_voice_01.svg);

            @media screen and (max-width: 768px) {
                height: 60px;
                margin-top: -35px;
            }
        }
    }

    &.txt-lesson {
        &::after {
            background-image: url(../img/common/txt_lesson_01.svg);
        }
    }

    &.txt-school {
        &::after {
            background-image: url(../img/common/txt_school_01.svg);
        }
    }

    &.txt-instagram {
        &::after {
            background-image: url(../img/common/txt_instagram_01.svg);
        }
    }

    &.txt-youtube {
      visibility: hidden;
        &::after {
            visibility: visible;
            background-image: url(../img/common/txt_youtube_01.svg);
        }
    }

    &.txt-information {
        @media screen and (max-width: 768px) {
            text-align: center;
        }

        &::after {
            margin-top: -40px;
            background: url(../img/common/txt_information_01.svg) left bottom/auto 100% no-repeat;

            @media screen and (max-width: 768px) {
                text-align: center;
                margin-top: -20px;
                background: url(../img/common/txt_information_01.svg) center bottom/auto 100% no-repeat;
                transform: translateX(5%);
            }

            @media screen and (max-width: 414px) {
                transform: translateX(10%);
            }
        }
    }

    & + * {
        margin-top: 40px;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
    }
}

.unit-title-02 {
    font-size: 2.6rem;
    font-weight: bold;
    letter-spacing: 0.1em;

    @media screen and (max-width: 768px) {
        font-size: 1.6rem;
    }

    & + * {
        margin-top: 20px;

        @media screen and (max-width: 768px) {
            margin-top: 15px;
        }
    }

    &.fs18 {
        font-size: 1.8rem;

        @media screen and (max-width: 768px) {
            font-size: 1.4rem;
        }
    }

    &.icon-beginer {
        &::before {
            content: "";
            display: inline-block;
            vertical-align: sub;
            margin-right: 0.75em;
            height: 1.25em;
            width: 1.25em;
            background: url(../img/common/icon_beginer_01.svg) center center/contain no-repeat;
        }

        & + * {
            margin-top: 10px;
        }
    }
}

.unit-title-03 {
    position: relative;
    width: 100%;
    max-width: 660px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: 1px solid #fff;
    border-bottom-color: transparent;
    font-weight: bold;
    font-size: 2.2rem;
    letter-spacing: 0.15em;
    color: #fff;

    &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        width: 100%;
        height: 10px;
        background: url(../img/common/title_bd_01.png) top center/auto 10px repeat-x;
    }

    & + * {
        margin-top: 40px;

        @media screen and (max-width: 768px) {
            margin-top: 20px;
        }
    }
}

.unit-title-04 {
    font-size: 2.8rem;
    font-weight: bold;
    letter-spacing: 0.1em;

    @media screen and (max-width: 768px) {
        font-size: 1.8rem;
    }
}

.unit-title-05 {
    font-size: 1.8rem;
    letter-spacing: 0.1em;
    color: $base_c;
    @include yugo;
    font-weight: bold;

    &.fs22 {
        font-size: 2.2rem;

        @media screen and (max-width: 768px) {
            font-size: 1.8rem;
        }
    }

    @media screen and (max-width: 768px) {
        font-size: 1.6rem;
    }
}

.unit-title-06 {
    display: inline-block;
    background: #f65c2c;
    color: #fff;
    font-weight: bold;
    padding: 5px 25px;
    letter-spacing: 0.15em;
    position: relative;

    &::after {
        content: "";
        bottom: -8px;
        left: 15px;
        position: absolute;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid #f65c2c;
        z-index: 1;
    }
}

.unit-btn-01 {
    .btn-inner {
        width: 100%;
        max-width: 460px;
        padding: 22px 10px;
        border-radius: 9999px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.15em;
        text-align: center;
        background: $base_c;
        color: #fff;
        border-bottom: 5px solid #385e11;
        transition: all 0.3s ease;

        @media screen and (min-width: 769px) {
            font-size: 2rem;
        }

        & > * {
            display: block;

            &::after {
                content: "\f061";
                @include fa5;
                margin-left: 1em;
            }
        }

        &.icon-contact {
            & > * {
                &::before {
                    content: "\f003";
                    font-family: Fontawesome;
                    margin-right: 1em;
                }
            }
        }

        &.btn-orange {
            background: $link_c;
            border-bottom-color: #a84305;

            &:hover {
                background: #a84305;
            }
        }

        &.btn-orange-01 {
            background: #f65c2c;
            border-bottom-color: #a9320d;

            &:hover {
                background: #a9320d;
            }
        }

        &.btn-blue {
            background: #2d8cff;
            border-bottom-color: #0e58b2;

            &:hover {
                background: #0e58b2;
            }
        }

        &.btn-small {
            max-width: 400px;
            padding: 15px;

            @media screen and (min-width: 769px) {
                font-size: 1.6rem;
            }

            @media screen and (max-width: 768px) {
                font-size: 1.2rem;
            }
        }

        &:hover {
            opacity: 1;
            background: #385e11;

            & > * {
                transform: translateY(1px);
            }
        }
    }
}

.unit-btn-02 {
    .btn-inner {
        position: relative;
        z-index: 1;
        text-align: center;
        width: 100%;
        max-width: 380px;
        padding: 12px 30px;
        border: 1px solid $base_c;
        color: $base_c;
        letter-spacing: 0.1em;
        font-weight: bold;
        border-radius: 9999px;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 100%;
            height: 100%;
            transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            border-radius: 9999px;
            background: $base_c;
            border: 1px solid $base_c;
        }

        & > * {
            position: relative;
            display: inline-block;

            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -2em;
                content: "\f105";
                font-family: Fontawesome;

                @media screen and (max-width: 768px) {
                    right: -15px;
                }
            }
        }

        &.btn-white {
            &:not(:hover) {
                border-color: #fff;
                color: #fff;
            }
        }

        &:hover {
            opacity: 1;
            color: #fff;
        }

        &:not(:hover) {
            &::after {
                width: 0;
                height: 0;
                opacity: 0;
            }
        }
    }

    &.reverse {
        .btn-inner {
            color: #fff;
            background: $base_c;

            &::after {
                background: #fff;
                border: 1px solid $base_c;
            }

            &:hover {
                opacity: 1;
                color: $base_c;
            }
        }
    }
}

.unit-contactbtn-01 {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    max-width: 460px;
    padding-top: 40px;

    .btn-inner {
        position: relative;
        z-index: 1;
        text-align: center;
        width: 100%;
        max-width: 460px;
        padding: 22px 20px;
        border: 1px solid #f57020;
        letter-spacing: 0.1em;
        background: #f57020;
        border-radius: 9999px;
        font-size: 2.4rem;
        transition: all 0.4s;

        input[type="submit"] {
            border: none;
            font-size: 2.4rem;
            color: #fff;
            width: 85%;
            font-weight: bold;
        }

        &:hover {
            opacity: 1;
            color: #f57020;
            background: #fff;

            input[type="submit"] {
                color: #f57020;
            }
        }
    }
}

.unit-link-01 {
    a {
        color: $link_c;

        & > * {
            &:after {
                content: "\f105";
                margin-left: 10px;
                font-family: FontAwesome;
            }
        }
    }
}

.unit-link-02 {
    a {
        font-size: 2.4rem;
        font-weight: bold;
        color: #d92139;

        @media screen and (max-width: 768px) {
            font-size: 1.6rem;
        }

        & > * {
            &:after {
                content: "\f061";
                margin-left: 1em;
                font-family: FontAwesome;
            }
        }
    }
}

.unit-link-03 {
    a {
        color: #0071ad;
        text-decoration: underline;
    }
}

.unit-dl-01 {
    & > dl {
        & > dt {
            color: $base_c;
            font-weight: bold;
            letter-spacing: 0.1em;

            @media screen and (min-width: 769px) {
                font-size: 1.8rem;
            }

            & + * {
                margin-top: 10px;
            }
        }

        & + dl {
            margin-top: 30px;
        }
    }
}

.unit-bg-01 {

    .bg-inner,
    .bg-inner-02 {
        display: inline-block;
        vertical-align: text-top;
        text-align: center;
        width: 100%;
        max-width: 140px;
        padding: 5px 10px;
        font-size: 1.3rem;
        letter-spacing: 0.1em;
        line-height: 1;
        border-radius: 9999px;
        background: #d92139;
        color: #fff;

        @media screen and (max-width: 768px) {
            font-size: 1.2rem;
        }

        @media screen and (max-width: 414px) {
            font-size: 1rem;
        }
    }

    .bg-inner-02 {
        max-width: 110px;
    }
}

@keyframes buru {
    0% {
        transform: translate(0px, 0px) rotateZ(0deg)
    }

    25% {
        transform: rotateZ(5deg);
    }

    50% {
        transform: rotateZ(0deg);
    }

    75% {
        transform: rotateZ(-5deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

.unit-pagetop-01 {
    position: fixed;
    z-index: 50;
    bottom: 50px;
    right: 30px;

    @media screen and (max-width: 768px) {
        right: 15px;
        bottom: 10px;
    }

    .pagetop-inner {
        display: block;
        height: 56px;
        width: 50px;
        background: url(../img/common/pagetop_img_01.svg) center center/contain no-repeat;

        @media screen and (max-width: 768px) {
            width: 48px;
            height: 48px;
        }

        & > * {
            font-size: 0;
        }

        &:hover {
            animation: buru 0.2s 1;
        }
    }
}

.unit-logo-01 {
    .logo-inner {
        display: inline-block;

        & > * {
            display: block;
            height: 50px;
            width: 220px;
            background: url(../img/common/logo.svg) left center/contain no-repeat;
            color: transparent;
        }
    }
}

.unit-plus-01 {
    &::after {
        content: "\f067";
        display: block;
        @include fa5;
        text-align: center;
        font-size: 36px;
    }
}

.unit-sns-01 {
    display: flex;

    & > * {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    *[class*="sns-"] {
        font-size: 0;
        line-height: 1;

        &::before {
            @include fa5-bra;
            font-size: 26px;

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }
    }

    .sns-instagram {
        &::before {
            content: "\f16d";
        }
    }

    .sns-youtube {
        &::before {
            content: "\f167";
        }
    }

    .sns-facebook {
        &::before {
            content: "\f39e";
        }
    }

    .sns-twitter {
        &::before {
            content: "\f099";
        }
    }

    .sns-blog {
        &::before {
            content: "\f5ad";
            @include fa5;
        }
    }

    .sns-site {
        &::before {
            content: "\f015";
            @include fa5;
        }
    }
}

.unit-poppins-01 {
    color: $base_c;
    letter-spacing: 0.15em;
    font-size: 1.2rem;
    font-weight: bold;
    @include smooth;

    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
}

.unit-hr-01 {
    margin: 30px auto;
    border: 0;
    border-top: 1px solid #ebeae8;
}

.unit-nav-01 {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 768px) {
        margin: 0 -10px -15px;
    }

    & > * {
        & > * {
            position: relative;
            font-size: 1.4rem;
            font-weight: bold;
            letter-spacing: 0.1em;
            padding-bottom: 10px;
            border-bottom: 3px solid #ccc;

            @media screen and (max-width: 768px) {
                font-size: 1.2rem;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                height: 3px;
                width: 0%;
                background: $base_c;
                transition: all 0.3s ease;
            }
        }

        &:hover,
        &.nav-current {
            & > * {
                &::after {
                    width: 100%;
                }
            }
        }

        @media screen and (min-width: 769px) {
            &:not(:last-child) {
                margin-right: 25px;
            }
        }

        @media screen and (max-width: 768px) {
            margin: 0 10px 15px;
        }
    }

    a:hover {
        opacity: 1;
    }
}

.unit-voice-01 {
    letter-spacing: 0.05em;
    font-size: 1.5rem;
    @include yugo;

    p {
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 768px) {
        font-size: 1.4rem;
    }
}

.unit-voice-02 {
    @media screen and (max-width: 768px) {
        margin-top: 15px;
    }
}

.unit-anchor-01 {
    background: #fff;
    border: 1px solid #000;
    text-align: center;

    &:not(:first-of-type) {
        border-left: none;
    }

    @media screen and (max-width: 768px) {
        &:not(:first-of-type) {
            border: 1px solid #000;
        }

        &:nth-of-type(n+3) {
            border-top: none;
        }

        &:nth-of-type(2n-1) {
            border-right: none;
        }
    }

    a {
        width: 100%;
        height: 100%;
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        padding: 9px 0;
        position: relative;

        &::after {
            content: "\f078";
            font-family: Fontawesome;
            margin-left: 0.5em;
            color: $base_c;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            font-size: 1.2rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
        }

        @media screen and (max-width: 414px) {
            font-size: 1.4rem;
        }

        &:hover {
            opacity: 1;

            &::after {
                top: 60%;
            }
        }
    }
}

.unit-anchor-02 {
    background: #fff;
    border: 1px solid #000;
    text-align: center;

    &:not(:nth-of-type(3n-2)) {
        border-left: none;
    }

    &:nth-of-type(n+4) {
        border-top: 0;
    }

    @media screen and (max-width: 768px) {
        &:not(:first-of-type) {
            border: 1px solid #000;
        }

        &:nth-of-type(n+3) {
            border-top: none;
        }

        &:nth-of-type(2n-1) {
            border-right: none;
        }
    }

    a {
        width: 100%;
        height: 100%;
        font-size: 1.8rem;
        font-weight: bold;
        letter-spacing: 0.1em;
        padding: 9px 0;
        position: relative;

        &::after {
            content: "\f078";
            font-family: Fontawesome;
            margin-left: 0.5em;
            color: $base_c;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            font-size: 1.2rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 1.5rem;
        }

        @media screen and (max-width: 414px) {
            font-size: 1.4rem;
        }

        &:hover {
            opacity: 1;

            &::after {
                top: 60%;
            }
        }
    }
}

.unit-telbox-01 {
    margin-top: 30px;

    .telnumber {
        @include poppins;
        font-size: 3.0rem;
        display: block;
        letter-spacing: 0.05em;
        color: $base_c;
        font-weight: 600;

        &::before {
            content: "\f095";
            font-family: Fontawesome;
            margin-right: 0.5em;
            font-size: 2.2rem;
        }
    }

    .time {
        font-size: 1.5rem;
        margin-top: 10px;

        @media screen and (max-width: 768px) {
            font-size: 1.3rem;
        }
    }
}

.unit-faq-01 {
    border: 4px solid $base_c;
    background-color: #fff;
    padding: 20px;
    position: relative;
    margin: 0 auto 20px;
    max-width: 900px;

    @media screen and (max-width: 768px) {
        padding: 5px 10px;
        margin: 0 auto 10px;
    }

    .q {
        padding: 10px 0 10px 50px;
        font-size: 1.8rem;
        font-weight: bold;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            font-size: 1.6rem;
            padding: 10px 0 10px 35px;
        }

        &::before {
            @include poppins;
            content: "Q";
            color: #fff;
            left: 20px;
            font-weight: 600;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            background: $base_c;
            position: absolute;
            text-align: center;
            font-size: 1.6rem;

            @media screen and (max-width: 768px) {
                top: 14px;
                left: 10px;
                width: 25px;
                height: 25px;
                line-height: 25px;
                border-radius: 25px;
                font-size: 1.3rem;
            }
        }

        .icon {
            &::before {
                content: "\f067";
                font-family: FontAwesome;
                right: 1px;
                bottom: -4px;
                font-size: 1.2rem;
                color: #fff;
                z-index: 1;
                position: absolute;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 30px 30px;
                border-color: transparent transparent $base_c transparent;
            }
        }

        &.active {
            .icon {
                &::before {
                    content: "\f068";
                }
            }
        }
    }

    .a {
        position: relative;
        border-top: 2px dashed #c7c7c7;
        margin: 20px 0 0;
        padding: 30px 0 10px 50px;
        display: none;

        @media screen and (max-width: 768px) {
            padding: 10px 0 0 35px;
            margin-top: 5px;
        }

        &::before {
            @include poppins;
            content: "A";
            color: #fff;
            background-color: #D92139;
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            position: absolute;
            font-weight: 600;
            left: 0;
            text-align: center;
            font-size: 1.6rem;

            @media screen and (max-width: 768px) {
                width: 25px;
                height: 25px;
                line-height: 25px;
                border-radius: 25px;
                font-size: 1.3rem;
                left: 0;
                top: 12px;
            }
        }

        .title-a {
            margin-bottom: 0;
            font-weight: 500;
            padding-top: 3px;

            @media screen and (max-width: 768px) {
                padding-top: 5px;
                margin-bottom: 5px;
            }
        }

        p {
            margin-bottom: 20px;
        }
    }
}

.unit-level-01 {
    border: 4px solid $base_c;
    background-color: #fff;
    padding: 20px;
    position: relative;
    margin: 0 auto 20px;
    max-width: 900px;

    @media screen and (max-width: 768px) {
        padding: 10px 15px;
        margin: 0 auto 10px;
    }

    .level {
        padding: 10px 0 10px;
        font-size: 2.2rem;
        font-weight: 500;
        cursor: pointer;
        @include poppins;

        @media screen and (max-width: 768px) {
            font-size: 1.6rem;
        }

        .icon {
            &::before {
                content: "\f067";
                font-family: FontAwesome;
                right: 1px;
                bottom: 0;
                font-size: 1.5rem;
                color: #fff;
                z-index: 1;
                position: absolute;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 0 30px 30px;
                border-color: transparent transparent $base_c transparent;
            }
        }

        &.active {
            .icon {
                &::before {
                    content: "\f068";
                }
            }
        }
    }

    .cont {
        position: relative;
        border-top: 2px dashed #c7c7c7;
        margin: 20px 0 0;
        padding: 30px 0 0;
        display: none;

        @media screen and (max-width: 768px) {
            padding: 20px 0 0;
            margin: 10px 0 0;
        }

        .title-a {
            margin-bottom: 20px;
            font-weight: 500;

            @media screen and (max-width: 768px) {
                margin-bottom: 15px;
            }
        }

        .box-bg-03 {
            margin-bottom: 30px;
        }

        iframe {
            max-width: 100%;

            @media screen and (max-width: 414px) {
                max-height: 200px;
            }
        }
    }

    .open {
        display: block;
    }
}

.unit-flow11b-01 {
    counter-increment: ct;
    color: #222831;
    position: relative;
    background: #fff;
    width: 18.77%;
    padding: 30px 20px 20px;
    border: 2px solid #000;

    @media screen and (max-width: 768px) {
        padding: 15px 20px 15px 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .txt-box {
            width: 100%;
        }
    }

    @media screen and (max-width: 414px) {
        padding: 10px 15px 10px 20px;
    }

    &::before {
        content: counter(ct);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: $base_c;
        color: #fff;
        @poppins;
        font-weight: 600;
        font-size: 1.4rem;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 768px) {
            bottom: 0;
            right: auto;
            transform: translate(-50%, 0);
        }
    }

    &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 10px 18px;
        border-color: transparent transparent transparent #000;
        position: absolute;
        right: -18px;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: translateY(-50%);

        @media screen and (max-width: 768px) {
            top: auto;
            bottom: -20px;
            left: 0;
            right: 0;
            margin: auto;
            transform: rotate(90deg);
        }
    }

    &:last-child {
        &:after {
            display: none;
        }
    }

    .txt-box {
        font-size: 1.5rem;

        p {
            img {
                margin: 0 auto;
            }
        }

        .text {
            display: block;
            margin: 15px 0 0;
            text-align: center;
            font-weight: bold;

            @media screen and (max-width: 768px) {
                margin: 10px 0 0;
            }
        }

        ul {
            li {
                display: inline-block;
            }
        }
    }
}

.unit-reason5b-01 {
    .img {
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .txt {
        padding: 20px 0;

        @media screen and (max-width: 768px) {
            padding: 15px 0;
        }
    }
}

.unit-teacher-01 {
    background: #fff;
    box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.06);
    padding: 20px;

    .box-flex-01 {

        .name-wrap,
        .img-wrap {
            width: auto;
        }
    }

    .name-wrap {
        .name {
            font-weight: bold;
            font-size: 2.2rem;
            letter-spacing: 0.1em;
            margin-bottom: 25px;
        }

        .lecture {
            font-size: 1.5rem;
            color: $base_c;
            line-height: 1.6;
        }

        .fee {
            font-weight: bold;
            font-size: 1.5rem;
            color: $base_c;
            line-height: 1.6;
        }
    }

    .comment {
        font-size: 1.4rem;
        letter-spacing: 0.05em;
        line-height: 1.57;
        padding: 15px 0;
    }

    .btn-wrap {
        padding: 0 10px;
    }

    .unit-btn-01 {
        .btn-inner {
            max-width: 140px;
            width: 140px;
            font-size: 1.4rem;
            letter-spacing: 0;
            padding: 10px;

            @media screen and (max-width: 768px) {
                width: 100%;
                max-width: 100%;

                &:last-of-type {
                    margin-top: 10px;
                }
            }

            span {
                &::after {
                    content: none;
                }
            }
        }
    }
}

.unit-difference-01 {
    border: 2px solid #000;

    .title {
        background: #D7D7D7;
        text-align: center;
        font-size: 2.2rem;
        letter-spacing: 0.15em;
        padding: 20px 0;
        position: relative;

        @media screen and (max-width: 768px) {
            font-size: 1.8rem;
        }

        &:after {
            position: absolute;
            content: "";
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #D7D7D7;
        }
    }

    .inner {
        background: #F5F5F5;
        padding: 35px 20px 60px;

        @media screen and (max-width: 768px) {
            padding: 35px 20px;
        }

        .unit-list-01 {
            li {
                &:not(:last-of-type) {
                    padding-bottom: 5px;
                }
            }
        }
    }
}

.unit-difference-02 {
    border: 2px solid #000;

    .title {
        background: #D5E5C3;
        text-align: center;
        font-size: 2.2rem;
        letter-spacing: 0.15em;
        padding: 20px 0;
        position: relative;

        @media screen and (max-width: 768px) {
            font-size: 1.8rem;
        }

        &:after {
            position: absolute;
            content: "";
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #D5E5C3;
            z-index: 1;
        }

        .sub-img {
            position: absolute;
            width: 103px;
            height: 100px;
            z-index: 1;
            right: 30px;
            top: -15px;

            @media screen and (max-width: 414px) {
                width: 72.1px;
                height: 70px;
                right: -15px;
            }
        }
    }

    .inner {
        background: #F5F8F1;
        padding: 35px 20px;
        position: relative;

        &:after {
            position: absolute;
            content: "";
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 13px solid #F5F8F1;
        }

        .unit-list-01 {
            li {
                &:not(:last-of-type) {
                    padding-bottom: 5px;
                }
            }
        }
    }

    .message {
        background: $base_c;
        color: #fff;
        text-align: center;
        padding: 30px 0 20px;

        .yellow {
            color: #FFF444;
        }
    }
}

.unit-attention-01 {
    padding-left: 1em;
    text-indent: -1em;
    line-height: 1.875;
    letter-spacing: 0.05em;
    font-weight: 500;

    &:before {
        content: "※";
        color: #D92139;
    }
}

.unit-school-01 {
    box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.04);

    a {
        display: flex;
        width: 100%;
        height: 100%;

        .thum {
            overflow: hidden;

            img {
                height: 100%;
                transition: all 1s ease;
                max-width: 100%;
            }
        }

        .text {
            background: #fff;
            width: 100%;
            flex: 1;
            padding: 15px;

            .title {
                font-size: 1.8rem;
                font-weight: bold;
                letter-spacing: 0.1em;
            }

            .address {
                color: #666666;
                font-size: 1.3rem;
                letter-spacing: 0.05em;
                line-height: 1.5;
            }
        }

        &:hover {
            opacity: 1;

            .thum {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.unit-trialarea-01 {
    display: inline-block;
    background: #f65c2c;
    color: #fff;
    font-weight: bold;
    padding: 5px 25px;
    letter-spacing: 0.15em;
}

.unit-youtube-movie-01 {
  width: 100%;
  aspect-ratio: 16 / 9;
}
