@charset "UTF-8";

@include max-screen($tablet) {}

@include max-screen($mobile) {}

.archive-bg-01 {
    background: #F5F8F1;
}

.sec-page {
    padding-top: 70px;
    padding-bottom: 60px;

    @include max-screen($tablet) {
        padding-top: 60px;
        padding-bottom: 50px;
    }

    @include max-screen($mobile) {
        padding-top: 50px;
        padding-bottom: 40px;
    }
}

.sec-page-01 {
    padding-top: 20px;
    padding-bottom: 60px;

    @include max-screen($tablet) {
        padding-bottom: 50px;
    }

    @include max-screen($mobile) {
        padding-bottom: 40px;
    }
}

.sec-page-02 {
    padding-top: 75px;
    padding-bottom: 80px;

    @include max-screen($tablet) {
        padding-top: 65px;
        padding-bottom: 70px;
    }

    @include max-screen($mobile) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.sec-page-03 {
    padding-top: 60px;
    padding-bottom: 65px;

    @include max-screen($tablet) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    @include max-screen($mobile) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

.sec-page-anchor {
    background: $base_c;
}

.sec-page-course,
.sec-page-contact,
.sec-page-online,
.sec-page-payment,
.sec-page-teacher,
.sec-page-school,
.sec-page-recruit,
.sec-page-wanted,
.sec-page-curriculum,
.sec-page-about,
.sec-page-lessonflow,
.sec-school-archive,
.sec-page-trial,
.sec-dl-top {
    background: #f5f8f1;
}

.sec-school-archive {
    .sec-inner {
        .unit-title-04 {
            padding-bottom: 35px;

            @media screen and (max-width: 768px) {
                padding-bottom: 20px;
            }
        }

        &:not(:last-of-type) {
            padding-bottom: 90px;

            @media screen and (max-width: 768px) {
                padding-bottom: 70px;
            }

            @media screen and (max-width: 414px) {
                padding-bottom: 40px;
            }
        }
    }
}

.section-school-single {
    section {
        &:nth-of-type(2n) {
            background: #f5f8f1;
        }
    }
}

.sec-page-wanted {
    .box-flex-01 {
        .w400 {
            &:nth-child(n+2) {
                @media screen and (max-width: 768px) {
                    padding-top: 5px;
                }
            }
        }
    }
}

.sec-step-01 {
    background: #6ea430;
    padding: 50px 0;
    position: relative;

    &::after {
        position: absolute;
        content: "";
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 30px solid #6ea430;
    }
}

.sec-dl-top,
.sec-dl-sample {
    .unit-text-01 {
        font-size: 1.5rem;
        font-feature-settings: "palt";

        @media screen and (max-width: 414px) {
            font-size: 1.4rem;
        }
    }
}

.sec-online-about {
    section {
        padding: 90px 0px 60px;

        @media screen and (max-width: 768px) {
            padding: 45px 0;
        }
    }

    .sec-about-flow {
        background: url(../img/lp/about/bg_01.png);
    }

    .sec-about-price{
        background: url(../img/lp/bg_01.png);

        .unit-lp-title-01 {
            &::before {
                content: "";
                display: block;
                width: 269px;
                height: 80px;
                margin: 0 auto;
                background: url(../img/lp/title_deco_09.png)center center /contain no-repeat;

                @media screen and (max-width: 414px) {
                    width: 135px;
                    height: 40px;
                }
            }
        }
    }

    .sec-about-howto{
        background: rgb(32, 226, 215);
        background: linear-gradient(90deg, rgba(#bee9c0, 1) 0%, rgba(#daf09f, 1) 100%);

        .unit-lp-title-01 {
            &::before {
                content: "";
                display: block;
                width: 268px;
                height: 80px;
                margin: 0 auto;
                background: url(../img/lp/about/title_deco_01.png)center center /contain no-repeat;

                @media screen and (max-width: 414px) {
                    width: 135px;
                    height: 40px;
                }
            }
        }
    }
}
