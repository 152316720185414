// 0.0.1
@charset "UTF-8";

.unit-pagenation-01 {
    text-align: center;

    a {
        display: inline-block;
        position: relative;
        margin: 0 10px;
        padding: 10px 10px;
        border: 1px solid $base_c;
        background-color: $base_c;
        font-size: 1.5rem;
        text-align: center;
        color: #ffffff;

        @include max-screen($tablet) {
            margin: 0 5px;
            font-size: 1.4rem;
        }

        &:hover {
            opacity: 1;
            background-color: #ffffff;
            color: $base_c;
        }
    }

    .prev,
    .next {
        a {
            min-width: 100px;

            @include max-screen($tablet) {
                min-width: 70px;
                padding: 10px 0 10px 5px;
            }

            &:before {
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-family: FontAwesome;
            }
        }
    }

    .prev {
        a {
            &:before {
                content: "\f104";
                left: 10px;
            }
        }
    }

    .return {
        a {
            min-width: 220px;

            @include max-screen($tablet) {
                min-width: 100px;
            }
        }
    }

    .next {
        a {
            &:before {
                content: "\f105";
                right: 10px;
            }
        }
    }
}

.unit-list-01 {
    li {
        position: relative;
        margin-top: 5px;
        padding-left: 1.3em;

        &:before {
            position: absolute;
            top: 1px;
            left: 1em;
            content: "・";
            margin-left: -1em;
        }

        &:first-child {
            margin-top: 0;
        }

        a {
            color: #0071BC;
            text-decoration: underline;
        }
    }

    &.orange{
        li{
            &:before{
                color: $link_c;
            }
        }
    }

    &.green{
        li{
            &:before{
                color: $base_c;
            }
        }
    }

    &.gray{
        li{
            &:before{
                color: #8a8a8a;
            }
        }
    }

    &.arrow {
        li {
            padding-left: 1.5em;

            &:before {
                position: absolute;
                left: 0;
                content: "\f138";
                margin-left: 0;
                font-family: FontAwesome;
                font-size: 1.4rem;
            }
        }
    }

    &.check {
        li {
            padding-left: 1.5em;

            &:before {
                position: absolute;
                left: 0;
                content: "\f058";
                margin-left: 0;
                font-family: "Font Awesome 5 Free";
                font-size: 1.4rem;
            }
        }
    }

    &.circle {
        li {
            padding-left: 1.3em;

            &:before {
                position: absolute;
                top: 3px;
                left: 0;
                content: "\f111";
                margin-left: 0;
                font-family: FontAwesome;
                font-size: 1.0rem;
            }
        }
    }
}

.unit-list-02 {
    counter-reset: num;

    .inner {
        position: relative;
        margin-top: 5px;
        padding-left: 2.0em;
        counter-increment: num;

        &:before {
            position: absolute;
            top: -2px;
            left: 0;
            content: counter(num)" ""." ;
            margin-left: 0;
            font-family: FontAwesome;
            font-size: 1.8rem;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}

.unit-numlist-01 {
    list-style: decimal;
    padding-left: 1.4em;

    & > li {
        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
}

.unit-note-01 {
    position: relative;
    padding-left: 1em;

    &.icon-red {
        &:before {}
    }

    &:before {
        content: "※";
        position: absolute;
        top: 0;
        left: 0;
    }

    + .unit-note {
        margin-top: 5px;
    }
}

.unit-tab-01 {
    position: relative;
    border-bottom: 4px solid $base_c;
    text-align: center;

    @include max-screen($tablet) {
        display: flex;
        flex-wrap: wrap;
        border-bottom: none;
    }

    li {
        display: inline-block;
        margin: 0 9px -1px 7px;
        font-size: 1.8rem;
        text-align: center;

        @include max-screen($tablet) {
            display: block;
            width: 50%;
            margin: 15px 0 0;
            font-size: 1.6rem;
        }

        @include max-screen($mobile) {
            font-size: 1.4rem;
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
            @include max-screen($tablet) {
                margin-top: 0;
            }
        }

        &:nth-of-type(odd) {
            @include max-screen($tablet) {
                padding-right: 7px;
            }
        }

        &:nth-of-type(even) {
            @include max-screen($tablet) {
                padding-left: 7px;
            }
        }

        a {
            min-width: 200px;
            padding: 11px 0 7px;
            border: 1px solid $base_c;
            background-color: $base_c;
            color: #ffffff;

            @include max-screen($tablet) {
                min-width: auto;
                min-width: inherit;
                width: 100%;
                padding: 8px 0 5px;
            }

            &.is-active,
            &:hover {
                opacity: 1;
                background-color: #ffffff;
                color: $base_c;
            }
        }
    }
}

.unit-youtube-01 {
    @include max-screen($tablet) {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
}
