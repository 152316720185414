@charset "UTF-8";

.section-home {
    .wrapper {
        @media screen and (max-width: 768px) {
            max-width: 670px;
        }
    }
    .section-home-about {
        .box-bd-01 {
            @media screen and (max-width: 768px) {
                margin-top: 50px;
            }
        }
    }
    .section-home-curriculum{
        .wrapper{
            position: relative;
            &::before{
                content: "";
                position: absolute;
                bottom: 36px;
                left: -46px;
                display: block;
                height: 68px;
                width: 74px;
                background: url(../img/common/point_bg_01.svg) center center/contain no-repeat;
                @media screen and (max-width: 768px) {
                    width: 56px;
                    left: -16px;
                }
            }
        }
    }
    .section-home-voice {
        .box-bd-01 {
            .bd-inner{
                .box-flex-01{
                    @media screen and (max-width: 768px) {
                        margin-top: 15px;

                        .unit-voice-01{
                            margin-top: 0;
                        }
                        .box-movie-01{
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .section-home-movie {
        .home-movie-heading {
            margin-bottom: -40px;

            @include max-screen($tablet) {
              margin-bottom: 0;
            }
        }

        .home-movie-title {
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 612px;
            height: 112px;
            background: url(../img/top/home_movie_heading.png)no-repeat;
            background-size: cover;
            border: none;
            position: absolute;
            top:  -50px;
            right: -20px;
            left: auto;
            bottom: auto;
            transform: translate(0, 0);

            @include max-screen($tablet) {
              display: none;
            }
          }
        }
    }
}
