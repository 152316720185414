@charset "UTF-8";

.box-flex-01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (min-width: 769px) {
        .w50p {
            width: 50%;

            .w50p-wrapper {
                max-width: $base_w / 2;
                margin-left: auto;
            }
        }

        $flexWidth:(200, 230, 340, 350, 400, 420, 440, 460, 530, 600, 640, 750);

        @each $width in $flexWidth {
            .w#{$width} {
                width: #{$width}px;
                margin: 0;
            }
        }

        .img-wrap {
            img {
                margin: 0 0 0 auto;
            }
        }
    }

    @media screen and (max-width: 768px) {
        & > * {
            width: 100%;

            & + .flex-img {
                margin-top: 30px;
            }
        }

        .flex-img {
            text-align: center;

            img {
                display: inline-block;
            }

            & + * {
                margin-top: 30px;
            }
        }

        .box-inner {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
    }

    .sp-order10 {
        @media screen and (max-width: 768px) {
            order: 10;
        }
    }

    .flex-link {
        width: auto;
    }

    &.flex-nowrap {
        @media screen and (max-width: 768px) {
            flex-wrap: nowrap;
        }
    }

    &.left {
        justify-content: flex-start;
    }
}

.box-flex-02 {
    display: flex;
    justify-content: space-between;

    $flexWidth: (200, 230, 340, 350, 400, 420, 440, 460, 530, 600, 640, 750);

    @each $width in $flexWidth {
        .w#{$width} {
            width: #{$width}px;
            max-width: 100%;
        }
    }

    @media screen and (min-width: 769px) {
        .w750 {
            flex: 1;
            margin-right: 50px;
        }
    }

    @media screen and (min-width: 415px)and (max-width: 768px) {

        $flexWidth: (200, 230, 340, 350, 400, 420, 440, 460, 530, 600, 640, 750);

        @each $width in $flexWidth {
            .w#{$width} {
                margin: 0;
            }
        }

        .w750 {
            flex: 1;
            margin-right: 20px;
        }

    }

    @media screen and (max-width: 414px) {
        flex-wrap: wrap;

        & > * {
            width: 100%;

            & + .flex-img {
                margin-top: 30px;
            }
        }

        .flex-img {
            text-align: center;

            img {
                display: inline-block;
            }

            & + * {
                margin-top: 30px;
            }
        }
    }
}

.box-col2-01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .inner {
        width: 49%;

        &:nth-of-type(n+3) {
            margin-top: 30px;
        }
    }

    @media screen and (max-width: 768px) {
        .inner {
            width: 100%;

            + .inner {
                margin-top: 30px;
            }
        }
    }

    @media screen and (max-width: 414px) {
        .inner {
            + .inner {
                margin-top: 15px;
            }
        }
    }
}

.box-col3-01 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .inner {
        width: 31.8%;

        &:not(:nth-child(3n-2)) {
            margin-left: calc(4.6% / 2);

            @media screen and (max-width: 768px) {
                margin-left: 0;
            }
        }

        &:nth-child(n+4) {
            margin-top: 30px;
        }

        @media screen and (max-width: 768px) {
            width: 49%;

            &:nth-child(2n) {
                margin-left: 2%;
            }

            &:nth-child(n+3) {
                margin-top: 20px;
            }
        }

        @media screen and (max-width: 414px) {
            width: 100%;

            &:nth-child(2n) {
                margin-left: 0%;
            }

            &:nth-child(n+2) {
                margin-top: 10px;
            }
        }
    }
}

.box-col3-02 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .inner {
        width: 31.8%;

        &:not(:nth-child(3n-2)) {
            margin-left: calc(4.6% / 2);

            @media screen and (max-width: 768px) {
                margin-left: 0;
            }
        }

        &:nth-child(n+4) {
            margin-top: 30px;
        }

        @media screen and (max-width: 768px) {
            width: 100%;

            &:nth-child(2n) {
                margin-left: 0%;
            }

            &:nth-child(n+2) {
                margin-top: 10px;
            }
        }
    }
}

.box-col4-01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .inner {
        width: 24%;

        &:nth-of-type(n+5) {
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        .inner {
            width: 49%;

            &:nth-of-type(n+3) {
                margin-top: 20px;
            }
        }
    }

    &.center {
        justify-content: center;
    }
}

.box-slide-01 {
    .slide-inner {
        display: block;

        &:hover {
            opacity: 1;
        }

        .slide-pc {
            position: relative;
            height: 690px;
            background: url(../img/top/mv_bg_01.jpg) center center/cover no-repeat;

            @media screen and (min-width: 1500px) {
                height: 800px;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }

            .pc-txt {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: 100%;

                img {
                    display: inline-block;
                }
            }
        }

        .slide-sp {
            @media screen and (min-width: 769px) {
                display: none;
            }

            img {
                width: 100%;
            }
        }
    }

    .slick-slide {
        outline: 0;
        height: auto!important;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
    }

    .slick-dots {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        bottom: 0;

        @media screen and (max-width: 768px) {
            height: 50px;
        }

        li {
            width: 10px;
            height: 10px;
            margin: 10px;

            button {
                width: 10px;
                height: 10px;
                border: 1px solid #000;
                border-radius: 50%;
                margin: 0;
                padding: 0;
                transition: all 0.3s ease;

                &::before {
                    display: none;
                }
            }

            &:hover,
            &.slick-active {
                button {
                    background: #000;
                }
            }
        }
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}

.box-contact-01 {
    padding: 60px 0;
    background: url(../img/common/contact_bg_01.jpg) center center/cover no-repeat;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
        background-image: url(../img/common/contact_bg_01_sp.jpg);

        .unit-title-03 {
            font-size: 2.4rem;
            letter-spacing: 0.1em;
            padding: 0;
            border: 0;

            @media screen and (max-width: 414px) {
                font-size: 4vw;
            }

            &::after {
                display: none;
            }
        }
    }

    .box-flex-01 {
        align-items: center;

        @media screen and (max-width: 768px) {
            text-align: center;

            .w460 {
                & + .w460 {
                    margin-top: 20px;
                }
            }
        }
    }
}

.box-sitemap-01 {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 769px) {
        & > li {

            @media screen and (-ms-high-contrast: active),
            screen and (-ms-high-contrast: none) {
                max-width: 35.5%;
            }

            & > a {
                font-weight: bold;
                letter-spacing: 0.1em;

                & + * {
                    margin-top: 20px;
                }
            }

            .sitemap-child {
                display: flex;
                flex-wrap: wrap;

                & > li {
                    width: 100%;
                    margin-bottom: 7.5px;

                    & > a {
                        display: block;
                        text-decoration: underline;
                        font-size: 1.5rem;
                        letter-spacing: 0.1em;
                    }
                }

                &.col2 {
                    & > li {
                        width: calc(50% - 5px);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;

        & > li {
            width: 100%;

            a {
                position: relative;
                display: block;
                text-align: center;
                font-weight: bold;
                background: #f5f8f1;
                padding: 7.5px 15px;
                margin-top: 2px;

                &::after {
                    content: "\f105";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    font-family: fontawesome;
                    color: #000;
                }
            }
        }

        .sitemap-sns {
            & > a {
                display: none;
            }

            .unit-sns-01 {
                justify-content: center;
                margin-top: 15px;

                a {
                    background: none;
                }
            }
        }
    }
}

.box-tab-01 {
    position: relative;

    .tab-detail {
        @media screen and (max-width: 768px) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        input {
            display: none;

            &:checked {
                & + label {
                    background: #f5f8f1;
                }
            }
        }

        label {
            display: inline-block;
            text-align: center;
            width: 50%;
            max-width: 250px;
            padding: 10px;
            border: 1px solid #000;
            font-weight: bold;
            cursor: pointer;

            @media screen and (min-width: 769px) {
                position: absolute;
                z-index: 10;
                top: 0;
                right: 249px;

                &:nth-of-type(2) {
                    right: 0;
                }
            }

            @media screen and (max-width: 768px) {
                margin-left: -1px;
            }
        }

        .detail-inner {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            min-height: 190px;

            @media screen and (max-width: 768px) {
                margin-top: 30px;

                &.row4 {
                    min-height: 360px;
                }

                &.row3 {
                    min-height: 260px;
                }

                &.row2 {
                    min-height: 160px;
                }
            }

            & > * {
                overflow: hidden;
                width: 100%;
                transition: all 0.5s ease;

                &.detail-news {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        input {
            &#list_news {
                &:checked {}

                &:not(:checked) {
                    & ~ .detail-inner .detail-news {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }

            &#list_blog {
                &:checked {}

                &:not(:checked) {
                    & ~ .detail-inner .detail-blog {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.box-archive-01 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .archive-inner {
        display: flex;

        .archive-figure {
            width: 100px;
            margin-right: 25px;
        }

        .archive-detail {
            flex: 1;

            .detail-title {
                font-weight: bold;
                text-decoration: underline;

                & + * {
                    margin-top: 10px;
                }
            }

            .detail-time {
                color: $base_c;
                font-size: 1.4rem;
                @include poppins;
                letter-spacing: 0.1em;
                @include smooth;

                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    &.col2 {
        & > * {
            width: calc(50% - 30px);

            @media screen and (min-width: 769px) {
                &:not(:nth-child(2n)) {
                    margin-right: 60px;
                }

                &:nth-child(n+3) {
                    margin-top: 30px;
                }
            }

            @media screen and (max-width: 768px) {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.box-archive-02 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .archive-inner {
        display: flex;

        .archive-figure {
            width: 100px;
            margin-right: 25px;
        }

        .archive-detail {
            flex: 1;

            .detail-title {
                font-weight: bold;
                text-decoration: underline;

                & + * {
                    margin-top: 10px;
                }
            }

            .detail-time {
                color: $base_c;
                font-size: 1.4rem;
                @include poppins;
                letter-spacing: 0.1em;
                @include smooth;

                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                }
            }
        }
    }

    &.col2 {
        & > * {
            width: calc(50% - 30px);

            @media screen and (min-width: 769px) {
                &:not(:nth-child(2n)) {
                    margin-right: 60px;
                }

                &:nth-child(n+3) {
                    margin-top: 60px;
                }
            }

            @media screen and (max-width: 768px) {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.box-col-01 {
    display: flex;
    flex-wrap: wrap;

    .col-inner {
        position: relative;
        height: 100%;
        box-shadow: 0 0 15px rgba(#000, 0.1);
        background: #fff;

        .col-figure {
            overflow: hidden;

            img {
                transition: all 1s ease;
            }
        }

        .col-detail {
            padding: 20px 30px 30px;

            @media screen and (max-width: 768px) {
                padding: 15px 20px 25px;
            }

            .detail-title {
                text-align: center;
                font-size: 2.2rem;
                font-weight: bold;

                @media screen and (max-width: 768px) {
                    font-size: 1.8rem;
                }

                & + * {
                    margin-top: 10px;
                }
            }

            .detail-txt {
                font-size: 0.9375em;
                color: #666;
            }
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border: 1px solid $base_c;
            opacity: 0;
            transition: all 0.3s ease;
            box-sizing: border-box;
        }

        &:hover {
            opacity: 0.9;
            box-shadow: 0 0 15px rgba(#000, 0.2);

            .col-figure {
                img {
                    transform: scale(1.05);
                }
            }

            &::after {
                border-width: 4px;
                opacity: 1;
            }
        }

        &.hover-none {
            &:hover {
                opacity: 1;
                box-shadow: 0 0 15px rgba(#000, 0.1);

                .col-figure {
                    img {
                        transform: none;
                    }
                }

                &::after {
                    content: none;
                }
            }
        }
    }

    &.col2 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(50% - 20px);

                &:not(:nth-child(2n)) {
                    margin-right: 40px;
                }

                &:nth-child(n+3) {
                    margin-top: 40px;
                }
            }
        }

        @media screen and (max-width: 768px) and (min-width: 768px) {
            & > * {
                width: calc(50% - 5px);

                &:not(:nth-child(2n)) {
                    margin-right: 10px;
                }

                &:nth-child(n+3) {
                    margin-top: 10px;
                }
            }
        }

        @media screen and (max-width: 767px) {
            max-width: 340px;
            margin-left: auto;
            margin-right: auto;

            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }

    &.col3 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(33.33% - 27px);

                &:not(:nth-child(3n)) {
                    margin-right: 40px;
                }

                &:nth-child(n+4) {
                    margin-top: 40px;
                }
            }
        }

        @media screen and (max-width: 768px) and (min-width: 768px) {
            & > * {
                width: calc(33.33% - 7px);

                &:not(:nth-child(3n)) {
                    margin-right: 10px;
                }

                &:nth-child(n+4) {
                    margin-top: 20px;
                }
            }
        }

        @media screen and (max-width: 767px) {
            max-width: 340px;
            margin-left: auto;
            margin-right: auto;

            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.box-col-02 {
    display: flex;
    flex-wrap: wrap;

    .col-inner {
        position: relative;
        height: 100%;
    }

    &.col3 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(33.33% - 14px);

                &:not(:nth-child(3n)) {
                    margin-right: 20px;
                }

                &:nth-child(n+4) {
                    margin-top: 20px;
                }
            }
        }

        @media screen and (max-width: 768px) and (min-width: 768px) {
            & > * {
                width: calc(33.33% - 7px);

                &:not(:nth-child(3n)) {
                    margin-right: 10px;
                }

                &:nth-child(n+4) {
                    margin-top: 20px;
                }
            }
        }

        @media screen and (max-width: 767px) {
            max-width: 340px;
            margin-left: auto;
            margin-right: auto;

            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.box-section-01 {
    padding: 70px 0 80px;

    @media screen and (max-width: 768px) {
        padding: 35px 0 50px;
    }
}

.box-section-02 {
    padding: 70px 0 80px;
    background: #f5f8f1;

    @media screen and (max-width: 768px) {
        padding: 35px 0 50px;
    }
}

.box-section-03 {
    padding: 70px 0 80px;
    background: url(../img/top/about_bg_01.jpg) center center/cover;

    @media screen and (max-width: 768px) {
        padding: 35px 0 50px;
    }

    &.bg-step {
        background-image: url(../img/top/step_bg_02.jpg);
    }

    &.bg-school {
        background-image: url(../img/top/school_bg_01.jpg);
    }
}

.box-section-04 {
    padding: 70px 0 80px;
    background: #6ea430;

    @media screen and (max-width: 768px) {
        padding: 35px 0 50px;
    }
}

.box-num-01 {
    display: flex;
    flex-wrap: wrap;
    counter-reset: num01;

    & > * {
        position: relative;
        counter-increment: num01;
        margin-top: 25px;

        &::before {
            content: counter(num01);
            position: absolute;
            z-index: 1;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background: $base_c;
            @include poppins;
            line-height: 1.5;
            font-size: 22px;
            font-weight: bold;
            color: #fff;
            border: 4px solid #fff;
            box-sizing: border-box;
        }
    }

    .num-inner {
        overflow: hidden;
        display: block;
        text-align: center;
        height: 100%;
        padding: 40px 15px 30px;
        background: #fff;
        box-shadow: 0 0 15px rgba(#000, 0.1);

        @media screen and (max-width: 768px) {
            padding: 30px 10px 25px;
        }

        .num-title {
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.5;

            @media screen and (max-width: 768px) {
                font-size: 1.6rem;
            }

            & + * {
                margin-top: 15px;
            }
        }

        .num-figure {
            line-height: 0;

            img {
                display: inline-block;
            }

            & + * {
                margin-top: 15px;
            }
        }

        .num-txt {
            font-weight: bold;
            font-size: 1.4rem;

            @media screen and (max-width: 768px) {
                font-size: 1.2rem;
            }
        }
    }

    .num-inner-01 {
        overflow: hidden;
        display: block;
        text-align: center;
        height: 100%;
        padding: 40px 27px 30px;
        background: #fff;
        box-shadow: 0 0 15px rgba(#000, 0.1);

        @media screen and (max-width: 768px) {
            padding: 30px 10px 25px;
        }

        .num-title {
            font-size: 2rem;
            font-weight: bold;
            line-height: 1.5;

            @media screen and (max-width: 768px) {
                font-size: 1.6rem;
            }

            & + * {
                margin-top: 15px;
            }
        }

        .num-figure {
            line-height: 0;

            img {
                display: inline-block;
            }

            & + * {
                margin-top: 15px;
            }
        }

        .num-txt {
            font-weight: bold;
            font-size: 1.4rem;

            @media screen and (max-width: 768px) {
                font-size: 1.2rem;
            }
        }
    }

    &.col4 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(25% - 15px);

                &:not(:nth-child(4n)) {
                    margin-right: 20px;
                }
            }
        }

        @media screen and (max-width: 768px) and (min-width: 414px) {
            & > * {
                width: calc(50% - 5px);

                &:not(:nth-child(2n)) {
                    margin-right: 10px;
                }

                &:nth-child(n+3) {
                    margin-top: 30px;
                }
            }
        }

        @media screen and (max-width: 413px) {
            max-width: 280px;
            margin-left: auto;
            margin-right: auto;

            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 40px;
                }
            }
        }
    }
}

.box-step-01 {
    display: flex;
    flex-wrap: wrap;
    counter-reset: step01;

    & > * {
        position: relative;
        counter-increment: step01;
    }

    .step-inner {
        padding: 25px 35px 30px;
        background: #fff;
        box-shadow: 0 0 30px rgba(#000, 0.2);

        @media screen and (max-width: 768px) {
            padding: 20px 20px 20px 120px;
        }

        .step-title {
            position: relative;

            @media screen and (min-width: 769px) {
                text-align: center;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 2px solid #000;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -2px;
                    left: 50%;
                    transform: translate(-50%, 100%);
                    display: block;
                    height: 0;
                    width: 0;
                    border: 6px solid transparent;
                    border-top-color: #000;
                }

                & + * {
                    margin-top: 30px;
                }
            }

            .title-step {
                display: block;
                color: $base_c;
                @include poppins;
                font-weight: bold;
                letter-spacing: 0.2em;

                &::after {
                    content: "0"counter(step01);
                    margin-left: 0.5em;
                }

                & + * {
                    margin-top: 5px;
                }
            }

            .title-inner {
                display: block;
                font-size: 2.4rem;
                letter-spacing: 0.1em;
                font-weight: bold;

                @media screen and (max-width: 768px) {
                    font-size: 1.8rem;
                }
            }
        }

        .step-figure {
            display: block;
            text-align: center;
            line-height: 0;

            @media screen and (max-width: 768px) {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 120px;
            }

            img {
                display: inline-block;

                @media screen and (max-width: 768px) {
                    width: 80px;
                }
            }

            & + * {
                margin-top: 15px;

                @media screen and (max-width: 768px) {
                    margin-top: 5px;
                }
            }
        }

        .step-txt {
            font-weight: bold;
            line-height: 1.5;

            @media screen and (min-width: 769px) {
                text-align: center;
            }

            @media screen and (max-width: 768px) {
                font-size: 1.2rem;
            }
        }
    }

    &.col3 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(33.33% - 67px);

                &:not(:nth-child(3n)) {
                    margin-right: 100px;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translate(100%, -50%);
                        display: block;
                        height: 36px;
                        width: 100px;
                        background: url(../img/common/step_arrow_01.svg) center center/64px 36px no-repeat;
                    }
                }

                &:nth-child(n+4) {
                    margin-top: 30px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            max-width: 420px;
            margin-left: auto;
            margin-right: auto;

            @media screen and (max-width: 414px) {
                max-width: 290px;
            }

            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.box-bd-01 {
    .bd-inner {
        border: 2px solid #000;
        background: #fff;
        padding: 35px 40px 45px;

        @media screen and (max-width: 414px) {
            padding: 20px 15px 25px;
        }

        .bd-title {
            position: relative;
            z-index: 1;
            width: 100%;
            max-width: 320px;
            padding: 5px;
            border-radius: 100px;
            margin: -40px auto 0;
            transform: translateY(-50%);
            text-align: center;
            background: $link_c;
            font-weight: bold;
            letter-spacing: 0.1em;
            color: #fff;

            @media screen and (max-width: 768px) {
                margin-top: -35px;
            }

            @media screen and (max-width: 414px) {
                margin-top: -20px;
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 100%);
                display: block;
                height: 0;
                width: 0;
                border: 5px solid transparent;
                border-top-color: $link_c;
            }
        }

        & + .bd-inner {
            margin-top: 30px;
        }
    }

    &.is-bd-bold {
        .bd-inner {
            border-width: 4px;
        }

        .bd-title {
            padding: 15px 10px;
        }
    }

    &.is-yellow {
        .bd-inner {
            border-color: #dee500;
        }

        .bd-title {
            background: #dee500;
            color: #333;
        }
    }

    &.is-bg-none {
        .bd-inner {
            background: none;
        }
    }

    &.is-fukidashi-none {
        .bd-title::before {
            display: none;
        }

    }

}

.box-bd-02 {
    .bd-inner {
        overflow: hidden;
        border: 2px solid #d92139;
        background: #fff;
        padding: 20px 30px;
        border-radius: 3px;

        @media screen and (max-width: 414px) {
            padding: 20px 15px 25px;
        }

        .bd-title {
            position: relative;
            z-index: 1;
            width: auto;
            padding: 10px;
            margin: -20px -30px 20px;
            text-align: center;
            background: #d92139;
            font-size: 2rem;
            font-weight: bold;
            letter-spacing: 0.1em;
            color: #fff;

            @media screen and (max-width: 768px) {
                font-size: 1.6rem;
                margin-top: -20px;
            }

            &.icon-caution {
                &::before {
                    content: "\f06a";
                    font-family: 'FontAwesome';
                    margin-right: 0.5em;
                    @include smooth;
                    font-weight: 100;
                }
            }
        }

        & + .bd-inner {
            margin-top: 30px;
        }
    }
}


.box-bg-01 {
    display: flex;
    flex-wrap: wrap;

    .bg-inner {
        padding: 40px;
        height: 100%;
        background: #fff;

        @media screen and (max-width: 768px) {
            padding: 30px 15px 20px;
        }

        .bg-title {
            display: table;
            padding: 10px 30px;
            background: $base_c;
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.15em;
            margin: -40px 0 30px -40px;

            @media screen and (max-width: 768px) {
                margin: -30px 0 15px -15px;
            }
        }
    }

    &.col1 {
        & > * {
            width: 100%;

            &:nth-child(n+2) {
                margin-top: 20px;
            }
        }
    }

    &.col2 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(50% - 10px);

                @media screen and (min-width: 769px) {
                    &:not(:nth-child(2n)) {
                        margin-right: 20px;
                    }

                    &:nth-child(n+3) {
                        margin-top: 20px;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.box-bg-02 {
    display: flex;
    flex-wrap: wrap;

    .bg-inner {
        padding: 40px 50px 50px;
        background: #fff;
        box-shadow: 0 0 15px rgba(#000, 0.1);

        @media screen and (max-width: 768px) {
            padding: 30px 15px 20px;
        }

        .bg-title {
            display: table;
            padding: 5px 50px;
            background: $base_c;
            border-radius: 9999px;
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.15em;
            margin: -60px 0 30px 0px;

            @media screen and (max-width: 768px) {
                margin: -40px 0 15px 0;
            }
        }

        &.bg-base {
            .bg-title {
                background: $base_c;
            }

            .fc-bg {
                color: $base_c;
            }
        }

        &.bg-link {
            .bg-title {
                background: $link_c;
            }

            .fc-bg {
                color: $link_c;
            }
        }
    }

    .bg-inner-01 {
        padding: 40px 40px 30px;
        background: #fff;
        box-shadow: 0 0 15px rgba(#000, 0.1);

        @media screen and (max-width: 768px) {
            padding: 30px 15px 15px;
        }

        .unit-text-01 {
            font-feature-settings: "palt";
        }

        .bg-title {
            display: table;
            padding: 5px 50px;
            background: $base_c;
            border-radius: 9999px;
            color: #fff;
            font-weight: bold;
            letter-spacing: 0.15em;
            margin: -60px 0 30px 0px;

            @media screen and (max-width: 768px) {
                margin: -40px 0 15px 0;
            }
        }

        &.bg-base {
            .bg-title {
                background: $base_c;
            }

            .fc-bg {
                color: $base_c;
            }
        }

        &.bg-link {
            .bg-title {
                background: $link_c;
            }

            .fc-bg {
                color: $link_c;
            }
        }
    }

    &.col1 {
        & > * {
            width: 100%;

            &:nth-child(n+2) {
                margin-top: 30px;
            }
        }
    }

    &.col2 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(50% - 20px);

                @media screen and (min-width: 769px) {
                    &:not(:nth-child(2n)) {
                        margin-right: 40px;
                    }

                    &:nth-child(n+3) {
                        margin-top: 40px;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 30px;
                }
            }
        }
    }

    &.col3 {
        @media screen and (min-width: 769px) {
            & > * {
                width: calc(32% - 20px);

                @media screen and (min-width: 769px) {
                    &:not(:nth-child(3n)) {
                        margin-right: 40px;
                    }

                    &:nth-child(n+4) {
                        margin-top: 40px;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            & > * {
                width: 100%;

                &:nth-child(n+2) {
                    margin-top: 30px;
                }
            }
        }
    }
}

.box-bg-03 {
    padding: 30px 40px;
    box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.06);

    @media screen and (max-width: 768px) {
        padding: 20px 15px;
    }

    &.white {
        background: #fff;
    }

    &.gray {
        background: #f7f7f6;
    }
}

.box-table-01 {
    table {
        width: 100%;

        tr {

            th,
            td {
                vertical-align: middle;
                padding: 10px 20px;
                font-size: 2.2rem;
                font-weight: bold;
                letter-spacing: 0.05em;
                border: 2px solid #000;
                background: #fff;

                @media screen and (max-width: 768px) {
                    padding: 5px 10px;
                    font-size: 1.6rem;
                }

                @media screen and (max-width: 414px) {
                    font-size: 4vw;

                    .fs26 {
                        font-size: 4vw;
                    }

                    .fs20 {
                        font-size: 3vw;
                    }

                    .fs15 {
                        font-size: 3vw;
                    }
                }
            }
        }

        thead {
            tr {
                th {
                    padding: 15px 20px;
                    font-size: 2rem;
                    font-weight: bold;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 1;
                    background: $base_c;
                    color: #fff;

                    @media screen and (max-width: 768px) {
                        padding: 5px 10px;
                        font-size: 1.4rem;
                        line-height: 1.5;
                    }

                    @media screen and (max-width: 414px) {
                        font-size: 4vw;
                    }
                }
            }
        }

        tbody {
            tr {
                th {
                    text-align: left;
                    background: #f5f8f1;

                    .detail {
                        color: #6ea430;
                        font-size: 1.4rem;
                        font-feature-settings: "palt";

                        &::before {
                            content: "\f0da";
                            padding-right: 3px;
                            font-family: Fontawesome;
                        }
                    }

                    @media screen and (max-width: 414px) {
                        letter-spacing: 0;

                        .detail {
                            font-size: 1.2rem;
                        }
                    }
                }

                td {
                    .per {
                        font-size: 1.3rem;
                        color: #666;
                    }

                    @media screen and (max-width: 414px) {
                        .per {
                            font-size: 2.4vw;
                        }
                    }
                }
            }
        }

        &.tl-fixed {
            table-layout: fixed;
        }
    }

    &.table-scroll {
        @media screen and (max-width: 768px) {
            width: 100%;
            overflow-x: scroll;

            table {
                width: 735px;

                @media screen and (max-width: 414px) {
                    width: 150vw;
                }
            }
        }
    }
}

.box-table-02 {
    table {
        width: 100%;

        @media screen and (max-width: 768px) {}

        tr {

            th,
            td {
                vertical-align: middle;
                padding: 20px;
                font-size: 2.2rem;
                font-weight: bold;
                letter-spacing: 0.05em;
                border: 2px solid #000;
                line-height: 1.27;

                @media screen and (max-width: 768px) {
                    padding: 5px 10px;
                    font-size: 1.6rem;
                }

                @media screen and (max-width: 414px) {
                    font-size: 4vw;

                    .fs26 {
                        font-size: 4vw;
                    }

                    .fs20 {
                        font-size: 3vw;
                    }

                    .fs16 {
                        font-size: 3vw;
                    }

                    .fs15 {
                        font-size: 3vw;
                    }
                }

                .sub {
                    display: block;
                    font-weight: 500;
                }
            }
        }

        thead {
            tr {
                th {
                    padding: 20px;
                    font-size: 2rem;
                    font-weight: bold;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 1;
                    background: #4d4d4d;
                    color: #fff;

                    &:first-of-type {
                        width: 290px;

                        @media screen and (max-width: 768px) {
                            width: 200px;
                        }
                    }

                    @media screen and (max-width: 768px) {
                        padding: 5px 10px;
                        font-size: 1.4rem;
                        line-height: 1.5;
                    }

                    @media screen and (max-width: 414px) {
                        font-size: 4vw;
                    }

                    &.us {
                        background: #f57020;
                    }

                    &.none {
                        background: none;
                        border: none;
                    }
                }
            }
        }

        tbody {
            tr {
                th {
                    text-align: left;
                    background: #eeeeee;

                    @media screen and (max-width: 414px) {
                        letter-spacing: 0;
                    }
                }

                td {
                    text-align: center;

                    &.us {
                        background: #feeee4;
                        color: #f57020;

                        &.red {
                            color: #d92139;
                        }
                    }

                    .comment {
                        position: relative;
                        text-align: center;
                        width: 100%;
                        max-width: 160px;
                        padding: 8px 12px;
                        font-size: 1.4rem;
                        letter-spacing: 0.1em;
                        line-height: 1;
                        border-radius: 9999px;
                        background: #d92139;
                        color: #fff;
                        margin: 0 auto 13px;
                        font-weight: bold;

                        @media screen and (max-width: 768px) {
                            font-size: 1.2rem;
                            padding: 5px 12px;
                        }

                        @media screen and (max-width: 414px) {
                            font-size: 1rem;
                        }

                        &::after {
                            position: absolute;
                            content: "";
                            bottom: -8px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 0;
                            height: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 8px solid #d92139;
                            z-index: 1;
                        }
                    }
                }
            }
        }

        &.tl-fixed {
            table-layout: fixed;
        }
    }

    &.table-scroll {
        @media screen and (max-width: 768px) {
            width: 100%;
            overflow-x: scroll;

            table {
                width: 640px;

                @media screen and (max-width: 414px) {
                    width: 150vw;
                }
            }
        }
    }
}

.box-table-03 {
    table {
        width: 100%;

        tr {

            th,
            td {
                vertical-align: middle;
                padding: 10px 20px;
                font-size: 2.2rem;
                font-weight: bold;
                letter-spacing: 0.05em;
                border: 2px solid #000;
                background: #fff;

                @media screen and (max-width: 768px) {
                    padding: 5px 10px;
                    font-size: 1.6rem;
                }

                @media screen and (max-width: 414px) {
                    font-size: 4vw;

                    .fs26 {
                        font-size: 4vw;
                    }

                    .fs20 {
                        font-size: 3vw;
                    }

                    .fs15 {
                        font-size: 3vw;
                    }
                }
            }

            .col01 {
                width: 34%;
            }
        }

        thead {
            tr {
                th {
                    padding: 15px 20px;
                    font-size: 2rem;
                    font-weight: bold;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 1;
                    background: $base_c;
                    color: #fff;

                    @media screen and (max-width: 768px) {
                        padding: 5px 10px;
                        font-size: 1.4rem;
                        line-height: 1.5;
                    }

                    @media screen and (max-width: 414px) {
                        font-size: 4vw;
                    }
                }
            }
        }

        tbody {
            tr {
                th {
                    text-align: left;
                    background: #f5f8f1;

                    @media screen and (max-width: 414px) {
                        letter-spacing: 0;
                    }
                }
            }
        }

        &.tl-fixed {
            table-layout: fixed;
        }
    }

    &.table-scroll {
        @media screen and (max-width: 768px) {
            width: 100%;
            overflow-x: scroll;

            table {
                width: 640px;

                @media screen and (max-width: 414px) {
                    width: calc(150vw - 60px);
                }
            }
        }
    }
}

.box-dl-01 {
    & > * {
        display: block;
        width: 100%;
        transition: all 0.1s ease;

        &:not(:last-child) {
            border-bottom: 1px solid #000;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }

    .dl-inner {
        display: flex;

        & > dt {
            font-size: 1.8rem;
            font-weight: bold;
            width: 110px;
            margin-right: 20px;

            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
                width: 80px;
                margin-right: 15px;
            }
        }

        & > dd {
            flex: 1;
            font-size: 1.4rem;
            color: #666;
            padding-top: 0.5em;

            @media screen and (max-width: 768px) {
                font-size: 1.2rem;
                padding-top: 0.2em;
            }
        }
    }

    a:hover {
        opacity: 1;
        color: $base_c !important;
        text-decoration: underline;

        .dl-inner {
            & > dd {
                color: $base_c !important;
            }
        }
    }
}

.box-dl-02 {
    & > * {
        display: block;
        width: 100%;
        transition: all 0.1s ease;
        border-bottom: 1px solid #000;
        padding: 0 20px 20px;
        margin-bottom: 20px;

        &:first-of-type {
            border-top: 1px solid #000;
            padding-top: 20px;
        }
    }

    .dl-inner {
        display: flex;

        @media screen and (max-width: 768px) {
            display: block;
        }

        & > dt {
            font-size: 1.5rem;
            font-weight: bold;
            width: 240px;
            margin-right: 20px;

            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
                width: 100%;
                margin-right: 15px;
            }
        }

        & > dd {
            flex: 1;
            font-size: 1.5rem;
            color: #666;

            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
                padding-top: 0.2em;
                width: 100%;
            }

            .inner {
                margin-bottom: 20px;
            }

            .unit-list-01 {
                li {
                    &:not(:last-of-type) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

.box-gallery-01 {
    img {
        width: 100%;

        @media screen and (min-width: 769px) {
            display: none;
        }
    }

    @media screen and (min-width: 769px) {
        height: 350px;
        background: url(../img/top/voice_bg_01.jpg) center center;
    }
}

.box-gallery-02 {
    position: relative;

    .gallery-img {
        max-height: 600px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .gallery-desc {
        width: 100%;
        background: rgba(#000, 0.4);
        padding: 40px 0;

        @media screen and (min-width: 769px) {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        @media screen and (max-width: 768px) {
            padding: 20px 0;
            background: $base_c;
        }

        .desc-title {
            color: #fff;
            font-size: 2.8rem;
            font-weight: bold;
            @include smooth;
            letter-spacing: 0.125em;

            @media screen and (max-width: 768px) {
                font-size: 1.6rem;
            }
        }
    }
}

.box-gallery-03 {
    position: relative;

    .gallery-img {
        position: relative;
        overflow: hidden;

        @media screen and (min-width: 769px) {
            height: auto;

            &::after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: url(../img/page/school/mv_txt_01.png) center center no-repeat;
                background-size: cover;

                //                @media screen and (max-width: 1360px) {
                //                    background: url(../img/page/school/mv_txt_01.png) top center no-repeat;
                //                    background-size: cover;
                //                }

                @media screen and (max-width: 768px) {
                    background-size: contain;
                }
            }
        }

        @media screen and (max-width: 768px) {
            &::after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: url(../img/page/school/mv_txt_01_sp.png) center center no-repeat;

                @media screen and (max-width: 768px) {
                    background-size: contain;
                }
            }
        }

        img {
            width: 100%;
        }
    }
}

.box-gallery-04 {
    position: relative;

    .gallery-img {
        position: relative;
        overflow: hidden;

        @media screen and (min-width: 769px) {
            height: auto;

            &::after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(#603813, 0.4) url(../img/page/online/mv_txt_01.png) center center no-repeat;

                @media screen and (max-width: 768px) {
                    background-size: contain;
                }
            }
        }

        img {
            width: 100%;
        }
    }
}

.box-movie-01 {
    line-height: 0;

    iframe {
        width: 100%;

        @media screen and (max-width: 414px) {
            height: calc(56.25vw - 34px);
        }
    }
}

.box-map-01 {
    line-height: 0;

    iframe {
        width: 100%;
        height: 340px;

        @media screen and (max-width: 414px) {
            height: 50vw;
        }
    }
}

.box-instagram-01 {
    display: flex;
    flex-wrap: wrap;

    &.col4,
    &#sbi_images {
        @media screen and (min-width: 415px) {
            & > * {
                width: calc(25% - 15px);

                &:not(:nth-child(4n)) {
                    margin-right: 20px;
                }

                &:nth-child(n+5) {
                    margin-top: 20px;
                }
            }
        }

        @media screen and (max-width: 414px) {
            max-width: 290px;
            margin-left: auto;
            margin-right: auto;

            & > * {
                width: calc(50% - 5px);

                &:not(:nth-child(2n)) {
                    margin-right: 10px;
                }

                &:nth-child(n+3) {
                    margin-top: 10px;
                }
            }
        }
    }

    a {
        display: block;
    }
}

.section-home-instagram {
    #sb_instagram {
        #sbi_images {
            display: flex;
            flex-wrap: wrap;

            @media screen and (min-width: 415px) {
                & > * {
                    width: calc(25% - 15px) !important;

                    &:not(:nth-child(4n)) {
                        margin-right: 20px !important;
                    }

                    &:nth-child(n+5) {
                        margin-top: 20px !important;
                    }
                }
            }

            @media screen and (max-width: 414px) {
                max-width: 100% !important;
                margin-left: auto !important;
                margin-right: auto !important;

                & > * {
                    width: calc(50% - 5px) !important;

                    &:not(:nth-child(2n)) {
                        margin-right: 10px !important;
                    }

                    &:nth-child(n+3) {
                        margin-top: 10px !important;
                    }
                }
            }

            a {
                display: block !important;
            }
        }
    }
}

.box-blog-01 {
    .inner {
        max-width: 860px;
        margin: auto;

        .title {
            border-bottom: 2px solid #000;
            padding: 0 0 20px;
            margin: 0 0 40px;
            letter-spacing: 0.1em;

            @media screen and (max-width: 768px) {
                padding: 0 0 15px;
                margin: 0 0 20px;
            }

            .unit-time-01 {
                display: block;
                margin-bottom: 10px;
                @include poppins;
                font-size: 1.3rem;
                color: $base_c;
            }

            .unit-title-01 {
                font-size: 2.2rem;
                @include yugo;
                line-height: 1.4em;

                @media screen and (max-width: 768px) {
                    font-size: 2rem;
                }
            }
        }

        .thum {
            margin: 0 0 40px;

            @media screen and (max-width: 768px) {
                margin: 0 0 20px;
            }

            img {
                display: block;
                margin: auto;
                max-width: 100%;
                height: auto;
            }
        }

        .main-text {
            @extend .box-blogdetail-01;

            p {
                @include yugo;
                font-size: 1.5rem;
                font-weight: 500;

                @media screen and (max-width: 768px) {
                    font-size: 1.4rem;
                }
            }

            img {
                margin: 0 auto;
            }
        }

        .unit-btn-02 {
            margin-top: 60px;

            @media screen and (max-width: 768px) {
                margin-top: 30px;
            }
        }
    }
}

.box-anchor-01 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .inner {
        width: 25%;

        @media screen and (max-width: 768px) {
            width: 50%;
        }
    }
}

.box-anchor-02 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .inner {
        width: calc(100% / 3);

        @media screen and (max-width: 768px) {
            width: 50%;
        }
    }
}

.box-form-01 {
    background: #fff;
    padding: 70px 75px;
    margin: 30px 0 0;

    @media screen and (max-width: 768px) {
        padding: 20px 15px;
    }

    .inner {
        margin: 0 0 30px;

        .required {
            &::after {
                display: none !important;
            }
        }

        dl {
            border-top: none;
            border-bottom: 1px solid #dcdcdc;
            padding: 25px 0;
            display: flex;

            @media screen and (max-width: 768px) {
                display: block;
                padding: 20px 0;
            }

            dt {
                width: 30%;
                padding: 0;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }

            dd {
                width: 70%;
                padding: 0;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-top: 5px;
                }

                input[type=email],
                input[type=mail],
                input[type=tel],
                input[type=text],
                input[type=url],
                select,
                textarea {
                    width: 100%;
                    border: 1px solid #dcdcdc;
                    background: #fff;
                    padding: 3px 17px;
                    border-radius: 3px;
                    outline: none;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }

                    &.your-pre,
                    &.your-age {
                        width: 100px;
                    }

                    &.your-age {
                        margin-right: 1em;
                    }
                }

                textarea {
                    width: 100%;
                    max-width: 100%;
                    height: auto;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }
                }
            }
        }

        p {
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .unit-center-01 {
        text-align: center;
    }

    .unit-btn-01 {
        text-align: center;

        input {
            background: #fff;
            border: none;
            color: #333;
            font-weight: bold;
            font-size: 2rem;
            max-width: 300px;
            width: 100%;
            height: 60px;
            transition: all .3s;

            @media screen and (max-width: 768px) {
                max-width: 260px;
            }

            &:hover {
                background: #333;
                color: #fff;
            }
        }
    }
}

.box-flow11b-01 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        margin: 0 auto;
    }

    > li {
        width: 18.77%;

        @media screen and (max-width: 768px) {
            width: calc(100% - 20px);
            margin-left: auto;
            margin-bottom: 20px;
        }
    }
}

.box-reason5b-01 {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 640px) {
        display: block;
    }

    > .inner {
        width: 31%;

        @media screen and (max-width: 768px) {
            width: 32%;
        }

        @media screen and (max-width: 640px) {
            width: 90%;
            margin-top: 20px;
            margin-left: auto;
            margin-right: auto;

            &:first-child {
                margin-top: 0;
            }
        }

        @media screen and (max-width: 414px) {
            width: 100%;
        }
    }
}

.box-teacher-01 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .inner {
        width: 31.8%;

        &:not(:nth-of-type(3n-2)) {
            margin-left: calc(4.6% / 2);

            @media screen and (max-width: 768px) {
                margin-left: 0;
            }
        }

        &:nth-of-type(n+4) {
            margin-top: 20px;
        }

        @media screen and (max-width: 768px) {
            width: 49%;

            &:nth-child(2n) {
                margin-left: 2%;

                @media screen and (max-width: 414px) {
                    margin-left: 0;
                }
            }

            &:nth-of-type(n+3) {
                margin-top: 20px;
            }
        }

        @media screen and (max-width: 414px) {
            width: 100%;

            &:nth-of-type(n+2) {
                margin-top: 10px;
            }
        }
    }
}

.box-pay-01 {
    > * {
        display: inline-block;
        vertical-align: top;
    }

    @media screen and (max-width: 414px) {
        .qr {
            display: block;
            margin-bottom: 15px;
        }

        .app {
            display: flex;

            a {
                &:first-of-type {
                    margin-right: 10px;
                }
            }
        }
    }
}

.box-policy-01 {
    .unit-title-05 {
        margin-top: 30px;
        margin-bottom: 15px;

        &.fs20 {
            font-size: 2.0rem;

            @media screen and (max-width: 768px) {
                font-size: 1.6rem;
            }
        }
    }

    .unit-list-02 {
        margin-bottom: 30px;

        .inner {
            &::before {
                color: $base_c;
                font-weight: bold;
            }
        }
    }
}

.box-trialdl-01 {
    dl:first-child {
        border-top: 1px solid #000;
    }

    dl {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #000;
        padding: 0 60px;

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            padding: 10px 20px;
        }

        @media screen and (max-width: 414px) {
            padding: 10px;
        }

        dt,
        dd {
            padding-top: 20px;
            padding-bottom: 17px;

            @media screen and (max-width: 768px) {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            @media screen and (max-width: 414px) {
                padding-bottom: 0;
            }
        }

        dt {
            width: 160px;
            padding-right: 10px;
            font-weight: bold;
            color: #000;
            letter-spacing: 0.1em;
            font-size: 1.8rem;

            @media screen and (max-width: 768px) {
                width: 120px;
            }

            @media screen and (max-width: 640px) {
                width: 100%;
                font-size: 1.6rem;
                padding-left: 0;
                padding-right: 10px;
            }
        }

        dd {
            width: calc(100% - 320px);
            color: #666;
            font-size: 1.5rem;
            letter-spacing: 0.05em;

            @media screen and (max-width: 640px) {
                width: 100%;
                font-size: 1.4rem;
            }

            &.btn {
                width: 280px;

                @media screen and (max-width: 768px) {
                    width: 200px;
                }

                @media screen and (max-width: 640px) {
                    width: 100%;
                }

                .unit-btn-02 {
                    a {
                        padding: 5px 30px;
                    }
                }
            }
        }
    }
}

.box-pdfdl-01 {
    dl:first-child {
        border-top: 1px solid #000;
    }

    dl {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #000;
        padding: 0 30px;

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            padding: 10px 20px;
        }

        @media screen and (max-width: 414px) {
            padding: 10px;
        }

        dt,
        dd {
            padding-top: 20px;
            padding-bottom: 20px;

            @media screen and (max-width: 768px) {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            @media screen and (max-width: 414px) {
                padding-bottom: 0;
            }
        }

        dt {
            width: 430px;
            padding-right: 10px;
            font-weight: bold;
            color: #000;
            letter-spacing: 0.1em;
            font-size: 1.8rem;

            @media screen and (max-width: 768px) {
                width: 100%;
                font-size: 1.6rem;
                padding-left: 0;
                padding-right: 10px;
            }

            &::before {
                content: "\f3b5";
                display: inline-block;
                @include fa5-bra;
                padding-right: 15px;
            }
        }

        dd {
            width: calc(100% - 510px);
            color: #666;
            font-size: 1.5rem;
            letter-spacing: 0.05em;

            @media screen and (max-width: 768px) {
                width: 100%;
                font-size: 1.4rem;
            }

            &.artist {
                flex: 1;
            }

            &.btn {
                width: 280px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    text-align: center;
                    margin-top: 10px;
                }

                .unit-btn-02 {
                    a {
                        padding: 6px 30px;
                    }
                }
            }
        }
    }
}

.box-onlinetitle-01 {
    background: #f2fddc;

    .title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        @media screen and (min-width: 769px) {
            height: 200px;
        }

        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
            padding: 30px 0;
        }

        .title-inner {
            display: flex;
            justify-content: center;
            width: 100%;

            @media screen and (max-width: 768px) {
                flex-wrap: wrap;
            }

            .title-main {
                font-size: 4rem;
                font-family: "FP-ヒラギノ角ゴ ProN W6";
                font-weight: 600;
                text-align: center;
                line-height: 1;
                letter-spacing: 0.08em;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    font-size: 2rem;

                    & + * {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}
