// 0.0.0
@charset "UTF-8";

@include max-screen($tablet) {}

@include max-screen($mobile) {}

#mainvisual {
    overflow: hidden;
    position: relative;
    padding: 0; //height: 520px;

    @include max-screen($tablet) {
        width: 100%;
        height: auto;
    }

    ul {
        transform: translate3d(0, 0, 0);

        li {
            img {
                width: 100% !important;

                @include max-screen($tablet) {
                    width: 100% !important;
                }
            }
        }
    }

    .slick-slider {
        .slick-arrow {
            display: block;
            z-index: 100;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            width: 66px;
            height: 66px;
            font-size: 0;
            border: 0;
            padding: 0;
            margin: 0;
            outline: none;
            background: none;

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: FontAwesome;
                font-size: 3.6rem;
                line-height: 1;
                text-align: center;
                color: #ffffff;
            }

            &.slick-prev {
                left: 15px;

                &:before {
                    content: "\f053";
                }
            }

            &.slick-next {
                right: 15px;

                &:before {
                    content: "\f054";
                }
            }
        }

        .slick-dots {
            z-index: 100;
            position: absolute;
            bottom: 26px;
            width: 100%;
            height: 21px;
            text-align: center;

            @include max-screen($tablet) {
                bottom: 7px;
            }

            & > li {
                display: inline-block;

                button {
                    &:before {
                        display: none;
                    }
                }

                & > * {
                    box-sizing: border-box;
                    display: block;
                    width: 13px;
                    height: 13px;
                    padding: 0;
                    margin: 0 15px;
                    border: 4px solid #ffffff;
                    border-radius: 10px;
                    background: none;
                    @include text-skip;
                    background-color: #ffffff;
                    transition: all 0.3s ease;

                    @include max-screen($tablet) {
                        width: 10px;
                        height: 10px;
                        margin: 0 10px;
                        border: 3px solid #ffffff;
                    }
                }

                &.slick-active,
                &:hover {
                    & > * {
                        opacity: 1;
                        background-color: $base_c;
                    }
                }
            }
        }
    }

    .maincatch {
        z-index: 10;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-51.5%, -57.8%);
        text-align: center;

        @include max-screen($tablet) {
            transform: translate(-50.1%, -57.8%);
        }

        .catch {
            margin: 0 auto;

            img {
                @include max-screen($tablet) {
                    width: 276.5px;
                }
            }
        }
    }
}

#lpMainvisual {
    position: relative;

    .video-wrap {
        position: relative;
        height: 600px;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            height: auto;
            max-height: 400px;
        }

        @media screen and (min-width: 769px) {
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                background: rgb(0, 0, 0);
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }

        video {
            width: 100%;
            vertical-align: bottom;
        }
    }

    .maincatch {
        color: #fff;
        position: absolute;
        //left: calc((100% - 1100px)/2);
        left: 70px;
        top: 50%;
        transform: translateY(-50%);
        font-feature-settings: "palt";

        @media screen and (max-width: 768px) {
            position: relative;
            background: url(../img/lp/bg_01.png);
            left: inherit;
            top: inherit;
            transform: none;
            color: #333;
            text-align: center;
            padding: 20px 0;
        }

        .main {
            font-family: "FP-ヒラギノ角ゴ ProN W6";
            font-size: 5.4rem;
            letter-spacing: 0.1em;
            font-weight: 600;
            line-height: 1.333;

            @media screen and (max-width: 768px) {
                font-size: 3.5rem;
            }

            @media screen and (max-width: 414px) {
                font-size: 2.4rem;
            }
        }

        .sub {
            font-weight: 300;
            font-family: "FP-ヒラギノ角ゴ ProN W3";
            font-size: 2.6rem;
            letter-spacing: 0.1em;

            @media screen and (max-width: 768px) {
                font-size: 2.2rem;
                color: #ff7864;
                line-height: 1.2;
                margin-top: 10px;
            }

            @media screen and (max-width: 414px) {
                font-size: 1.8rem;
            }

            .deco {
                font-weight: 600;
                font-size: 3rem;
                font-family: "FP-ヒラギノ角ゴ ProN W6";
                display: inline-block;

                .dot {
                    position: relative;

                    &::before {
                        content: "・";
                        position: absolute;
                        top: -1em;
                        left: 50%;
                        transform: translateX(-50%);

                        @media screen and (max-width: 768px) {
                            top: -0.85em;
                        }

                        @media screen and (max-width: 414px) {
                            top: -0.95em;
                        }
                    }
                }

                @media screen and (max-width: 768px) {
                    font-size: 2.4rem;
                }

                @media screen and (max-width: 414px) {
                    font-size: 2rem;
                }
            }

            .barlow {
                font-weight: 600;
                font-size: 4.0rem;
                letter-spacing: 0.02em;

                @media screen and (max-width: 768px) {
                    font-size: 3rem;
                }
            }

            .semi {
                font-weight: 600;
                font-family: "FP-ヒラギノ角ゴ ProN W6";
                font-size: 3rem;
                margin-top: 30px;

                @media screen and (max-width: 768px) {
                    font-size: 2.4rem;
                    margin-top: 15px;
                }

                @media screen and (max-width: 414px) {
                    font-size: 2rem;
                }
            }
        }

        .unit-lp-btn-01 {
            margin-top: 50px;

            @media screen and (max-width: 768px) {
                margin-top: 25px;
            }

            a {
                width: 440px;
                max-width: 100%;
                font-size: 2.4rem;
                font-weight: 600;
                font-family: "FP-ヒラギノ角ゴ ProN W6";
                letter-spacing: 0.1em;
                font-feature-settings: "palt";

                @media screen and (max-width: 768px) {
                    font-size: 2rem;
                    width: 400px;
                }

                @media screen and (max-width: 414px) {
                    font-size: 1.6rem;
                    max-width: 95%;
                }
            }
        }
    }
}
