@charset "UTF-8";

html,
body {
    // タブレット用にフィットするサイズ（コンテンツの最大サイズ）
    min-width: ($base_w + 40px);
    @include max-screen($tablet) {
        min-width: inherit;
        min-width: auto;
    }
    &.is-open {
        @include max-screen($tablet) {
            overflow: hidden;
            height: 100%;
        }
    }
}
html {
    height: 100%;
    font-size: 62.5%;
}
body {
    height: 100%;
    line-height: 1.7;
    font-size: 16px;
    font-size: 1.6rem;
    font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
    color: $font_c;
    -webkit-text-size-adjust: 100%;
    @include max-screen($tablet) {
        line-height: 1.5;
        font-size: 14px;
        font-size: 1.4rem;
    }
}
a {
    display: inline-block;
    color: inherit;
    transition: all 0.3s linear;
    outline: none;
    text-decoration: none;
    &:visited {
        color: inherit;
    }
    &:active {
        color: inherit;
    }
    &:hover {
        opacity: 0.7;
        color: inherit;
    }
    &:focus {
        outline: none;
    }
}
img {
    display: block;
    max-width: 100%;
    height: auto;
    pointer-events: none;
}
