// 0.0.0
@charset "UTF-8";

// wp-pagenavi
.wp-pagenavi {
    margin-top: 56px;
    font-size: 1.6rem;
    text-align: center;

    @include max-screen($tablet) {
        margin-top: 28px;
        font-size: 1.4rem;
    }

    /*
    .first,
    .last,
    .pages{
        display: none;
    }
    */
    .extend,
    .current {
        background-color: $base_c;
        border-color: $base_c;
        color: #fff;
    }

    .extend {
        min-width: auto;
        min-width: inherit;
        padding: 0;

        @include max-screen($mobile) {
            display: none;
        }
    }

    > a,
    > span {
        vertical-align: middle;
        display: inline-block;
        min-width: 50px;
        min-height: 50px;
        margin: 0 10px 4px;
        padding: 0 5px;
        border: 1px solid $base_c;
        background-color: #fff;
        text-align: center;
        color: $base_c;
        text-decoration: none;
        transition: all 0.3s linear;
        border-radius: 50%;
        @include poppins;
        font-weight: 500;
        line-height: 50px;

        @include max-screen($tablet) {
            min-width: 40px;
            min-height: 40px;
            padding: 0;
            line-height: 40px;
            margin: 0 6px 4px;
        }

        @include max-screen($mobile) {}

    }

    > a {
        &:hover {
            opacity: 1;
            background-color: #ffffff;
            color: $base_c;
        }
    }
}

.wpcf7-spinner,
.ajax-loader {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 150%);
}
