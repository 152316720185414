// 0.0.0
@charset "UTF-8";

// 外部サイトカラー
// 170927
$tw_c:#55ACEE; // Twitter
$fb_c:#3b5998; // Facebook
$gg_c:#dd4b39; // Google+
$pint_c:#bd081c; // Pinterest
$lnked_c:#0077B5; // LinkedIn
$tumb_c:#35465c; // tumblr
$yout_c:#cd201f; // YouTube
$ever_c:#6FB536; // Evernote
$line_c:#1dcd00; // LINE
$insta_c:#3f729b; // Instagram
$skype_c:#00aff0; // Skype
$drop_c:#1f8ce6; // Dropbox


// サイト基本情報
$font_c:#333333; // フォントベースカラー
$base_c:#6ea430; // ページベースカラー
$link_c:#f57020; // リンクカラー
$base_w:1100px; //ベースの横幅
$table_brc:#D6D6D6; // テーブルボーダー色
$table_bac:#E7E7EB; // テーブル背景色
